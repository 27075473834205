.o-catalog {
	overflow: auto;

	.o-catalog__title > h3 {
		font-size: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__filters {
		min-width: 225px;
		max-width: 225px;
		margin-right: $spacer-2x-small;
	}

	&__filter {
		margin-top: $spacer-x-small;
		margin-left: $spacer-x-small;
	}

	&__results {
		display: flex;
		flex: 1;

		> .a-card {
			width: 100%;
		}
	}

	&__grid {
		display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		column-gap: $spacer-2x-small;

		> .a-card {
			width: 100%;
			overflow: hidden;
		}
	}

	&--create {
		display: flex;
		flex-direction: column;
		max-width: 500px;

		.a-input {
			&.a-input--horizontal {
				margin-bottom: 0;
			}

			.a-input__label {
				margin-bottom: $spacer-x-small;
			}
		}
	}

	&__preview {
		flex: 1;
		max-height: 500px;
	}
}
