.m-catalog-item {
	h4 {
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		overflow: hidden;

		span {
			flex: 1;
			display: block;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&__favourite {
		.a-button {
			font-size: 1.1rem;
		}
	}

	.a-button--transparent {
		color: $gray;
	}

	&__preview {
		height: 200px;
		position: relative;
		margin: $spacer-x-small 0;
	}

	&__edit {
		position: absolute;
		bottom: $spacer-x-small;
		left: $spacer-x-small;
	}

	&__status {
		position: absolute;
		top: $spacer-x-small;
		left: $spacer-x-small;
	}

	&__delete {
		position: absolute;
		top: $spacer-x-small;
		right: $spacer-x-small;
	}

	&__add {
		position: absolute;
		bottom: $spacer-x-small;
		right: $spacer-x-small;
	}
}
