.m-loading {
	padding: $spacer-x-large;
	text-align: center;
	flex: 1;
	width: 100%;

	&--center {
		align-items: center;
		justify-content: center;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 50000;
	}

	.a-icon {
		font-size: 1.2rem;
	}

	&--large {
		.a-icon {
			font-size: 3em;
		}
	}

	&--xl {
		background-color: white;

		.a-icon {
			color: rgb(32, 32, 32);
			font-size: 50vw;
		}
	}
}
