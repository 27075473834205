.m-hardware-item {
	h4 {
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		overflow: hidden;

		span {
			flex: 1;
			display: block;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&__favourite {
		.a-button {
			font-size: 1.1rem;
		}
	}

	.m-hardware-item__preview {
		border-radius: $border-radius;
		overflow: hidden;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.a-button--transparent {
		color: $gray;
	}

	&__preview {
		height: 200px;
		position: relative;
		margin: $spacer-x-small 0;
	}

	&__edit {
		position: absolute;
		bottom: $spacer-x-small;
		left: $spacer-x-small;
	}

	&__status {
		position: absolute;
		top: $spacer-x-small;
		left: $spacer-x-small;
	}

	&__delete {
		position: absolute;
		top: $spacer-x-small;
		right: $spacer-x-small;
	}

	&__add {
		position: absolute;
		bottom: $spacer-x-small;
		right: $spacer-x-small;
	}

	&__image-navigation {
		position: absolute;
		bottom: $spacer-x-small;
		left: 50%;
		z-index: 1;
		transform: translateX(-50%);

		ul {
			list-style: none;
			display: flex;
			margin: 0;
			padding: 0;
		}
	}

	&__image-navigation-item {
		height: 10px;
		width: 10px;
		border-radius: 50%;
		background-color: $light-gray;
		border: 1px solid transparent;
		cursor: pointer;
		margin: 0 $spacer-3x-small;

		&--active {
			background-color: $primary;
		}
	}

	&__variants {
		margin-top: $spacer-small;
	}

	&__variant {
		border: none;
		display: inline-block;
		height: 20px;
		width: 30px;
		border-radius: $border-radius;
		margin-right: $spacer-x-small;
		outline: 2px solid transparent;
		border: 1px solid transparent;
		cursor: pointer;

		&--selected, &--selected:focus {
			outline: 2px solid $primary !important;
			border: 1px solid $input-background;
		}

		&--white { background-color: white }
		&--black { background-color: black }
		&--other { background-color: black }
		&--grey { background-color: grey }
		&--messing { background-color: #D5BC76; }
		&--brown { background-color: #884D19 }
		&--dark_brown { background-color: rgb(95, 9, 9) }
		&--beige { background-color: beige }
		&--green { background-color: green }
		&--red { background-color: red }
		&--aluminium { background-color: #A8ACB6 }
		&--copper { background-color: #A8723E }
		&--bronze { background-color: #BC7F40 }
		&--gold { background-color: gold }
		&--silver { background-color: silver }
		&--nickel { background-color: #9EA4AB }
		&--metal { background-color: #999BA0 }
		&--inox { background-color: #CFCFCC }
	}
}
