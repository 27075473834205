.a-canvas {
	height: 100%;
	width: 100%;
	position: relative;

	user-select: none; /* standard syntax */
	-webkit-user-select: none; /* webkit (safari, chrome) browsers */
	-moz-user-select: none; /* mozilla browsers */
	-khtml-user-select: none; /* webkit (konqueror) browsers */
	-ms-user-select: none; /* IE10+ */

	&__canvas {
		height: 100%;
		width: 100%;
		border-radius: $border-radius;
		overflow: hidden;
	}

	&__temp {
		background-color: $lighter-gray;
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: black;
		border-radius: $border-radius;
		overflow: hidden;
	}

	&__fullscreen {
		position: absolute;
		top: $spacer-2x-small;
		right: $spacer-2x-small;

		.a-button {
			color: $darker-gray;

			.a-icon {
				font-size: 1.5rem;
			}
		}
	}

	&__isolation {
		position: absolute;
		top: $spacer-2x-small;
		left: $spacer-2x-small;

		.a-button {
			color: $darker-gray;

			.a-icon {
				font-size: 1.5rem;
			}
		}
	}

	&__hint {
		position: absolute;
		top: $spacer-2x-small;
		left: $spacer-2x-small;
		text-align: left;
		background-color: $medium-gray;
		padding: $spacer-2x-small $spacer-small;
		border-radius: $border-radius;
		box-shadow: $box-shadow;
	}

	&__measurements {
		position: absolute;
		bottom: $spacer-2x-small;
		right: $spacer-2x-small;
		text-align: left;
		background-color: $medium-gray;
		padding: $spacer-2x-small $spacer-small;
		border-radius: $border-radius;
		box-shadow: $box-shadow;

		h3 {
			margin: $spacer-2x-small 0 0 0;
			padding: 0;
			font-size: 16px;
			color: $white;
		}

		p {
			margin: 0;
			padding: 0;
			font-size: 14px;
			color: $light-gray;
		}
	}

	&__buttons {
		position: absolute;
		top: $spacer-2x-large * 2;
		right: $spacer-2x-small;
		display: flex;
		flex-direction: column;
		background-color: $dark-gray;
		padding: $spacer-2x-small;
		border-radius: $border-radius;

		img {
			box-shadow: $box-shadow;
		}

		.a-input__label {
			width: 20px;
		}
	}

	&__button {
		border: none;
		background: none;
		padding: 0;
		cursor: pointer;
		margin: 0.2rem 0.1rem;
		height: 44px;
		width: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0.75;

		&--disabled {
			cursor: not-allowed;
			opacity: 0.5;
			filter: grayscale(1);
		}

		&:hover {
			opacity: 1;
		}

		&--active {
			opacity: 1;
			outline: 1px solid $primary;
			border-radius: $border-radius;
		}

		img {
			height: 44px;
			width: 44px;
			display: block;
		}
	}

	&__button-divider {
		width: 100%;
		height: 6px;
		box-shadow: $box-shadow;
		background-color: $input-background;
		margin: $spacer-x-small 0;
	}
}
