.m-loader {
	width: 100%;
	max-width: 100vw;
	height: $spacer-2x-small;
	position: relative;
	overflow: hidden;

	.m-loading-bar {
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100%;
		overflow: hidden;
		pointer-events: none;
		z-index: 50;

		&::after {
			content: "";
			position: absolute;
			top: -200px;
			left: 0;
			width: 100%;
			height: 200px;
			border-radius: 50%;
			// background-color: red;
			box-shadow: 0 0 10px 0 $primary;
			animation-duration: 1.7s;
			animation-fill-mode: forwards;
			animation-iteration-count: infinite;
			animation-name: loadingBar;
			animation-timing-function: linear;
		}
	}
}

@keyframes loadingBar {
    0% {
        left: -80%
    }
    100% {
        left: 100%
    }
}
