.m-edge-band-example {
	display: flex;
	justify-content: center;

	p {
		margin: 0;
		padding: 0;
		font-weight: normal;
	}

	&__width {
		writing-mode: vertical-rl;
		text-orientation: sideways;
		text-align: center;
		margin-bottom: 25px !important;
		margin-right: $spacer-2x-small !important;
	}

	&__wrapper {
		background-color: $gray;
		display: inline-flex;
		flex-direction: column;
		align-items: center;
		padding: $spacer-2x-small $spacer-small;
	}

	&__container {

		> p {
			text-align: center;
			margin-top: $spacer-2x-small;
		}
	}

	&__horizontal {
		background-color: $gray;
		display: flex;
		flex-direction: row;
		align-items: center;

		img {
			width: 70px;
			height: 33px;
			display: inline-block;
			margin: $spacer-2x-small $spacer-small;
		}
	}
}
