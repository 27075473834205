.o-header {
	background-color: $dark-gray;
	color: $lighter-gray;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-areas: "content logo user-info";
	padding: $spacer-small $spacer;
	height: $header-height;
	box-shadow: $box-shadow;
	position: relative;
	z-index: 20;

	&__content {
		grid-area: "content";
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	&__item {
		display: inline-block;
		padding: 0 $spacer;
		height: 20px;
		position: relative;

		.a-icon {
			font-size: 1.2rem;
			line-height: 1.1;
		}

		&:first-of-type {
			padding-left: 0;
		}

		&:not(:last-of-type)::after {
			content: "";
			position: absolute;
			right: 0;
			top: 0;
			width: 1px;
			height: 20px;
			background-color: $gray;
		}
	}

	&__logo {
		grid-area: "logo";
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			width: 150px;
			height: auto;
		}
	}

	&__user-info {
		grid-area: "user-info";
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
}
