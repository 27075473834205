.m-board-table {
	width: 100%;
	border-collapse: collapse;
	position: relative;

	&.m-board-table--sticky thead {
		position: sticky;
		top: 70px;
	}

	thead {
		background-color: #333333;

		tr th {
			text-align: left;
			font-weight: 300;
			font-size: 12px;
			padding: $spacer-2x-small $spacer-2x-small $spacer-x-small $spacer-2x-small;
		}
	}

	tbody {
		tr {
			cursor: pointer;
			outline: 1px solid transparent;
			border-radius: $border-radius;

			&:hover td, &.m-board-table__row--active td {
				background-color: $medium-gray;

				&:first-child {
					border-top-left-radius: $border-radius;
					border-bottom-left-radius: $border-radius;
				}

				&:last-child {
					border-top-right-radius: $border-radius;
					border-bottom-right-radius: $border-radius;
				}
			}

			// &.m-board-table__row--alternative:hover td, &.m-board-table__row--alternative.m-board-table__row--active td {
			// 	background-color: transparent !important;
			// 	border-bottom: 1px solid $primary;
			// }

			td {
				text-align: left;
				font-weight: 300;
				font-size: 14px;
				padding: $spacer-2x-small;
			}
		}
	}

	&__colour {
		width: 10px;
		height: 10px;
		background-color: white;
	}

	&__price {
		height: 5px;
		width: 50px;
		background-color: $gray;

		&-inner {
			background-color: $white;
			height: 5px;
		}
	}
}
