.m-project-header {
	display: flex;

	&__info {
		flex: 1;
		h3 {
			font-size: 20px;
			color: $primary;
			margin: 0;
			padding: 0;
		}
	}

	&__name {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: flex-end;

		.a-icon {
			margin-left: $spacer-small;
		}
	}

	&__status {
		flex: 1;
		text-align: right;
	}
}
