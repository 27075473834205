
.a-tooltip {
    text-align: center;
    z-index: 100;
    position: fixed;
    padding: 6px 12px;
    font-size: 0.66rem;
    font-weight: 600;
    line-height: initial;
    color: white;
    width: auto;
    background: #111111ee;
    box-sizing: border-box;
    opacity: 1;
    transform: translate(-50%, 0%);
    animation-fill-mode: forwards;
    pointer-events: none;
}

// @keyframes tooltip-slide {
//   0% {
//     opacity: 0;
//     transform: translate(-50%, -30%);
//   }
//   100% {
//     opacity: 1;
//     transform: translate(-50%, 0);
//   }
// }
