.m-project-designer {
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow: hidden;

	.a-card {
		width: 100%;
	}

	&__ribbon {
		margin-bottom: $spacer-2x-small;
	}

	&__empty {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__container {
		height: 100%;
	}

	&__canvas {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		transition: opacity 0.15s ease-in-out;
		opacity: 0.7;
		pointer-events: none;
		will-change: opacity;
	}

	.active {
		opacity: 1;
		z-index: 100;
		pointer-events: auto;
	}

	&__container-card {
		display: flex;
		flex: 1;
		flex-direction: column;
		position: relative;
		overflow: hidden;
	}

	&__add {
		border-radius: $border-radius;
		padding: $spacer-2x-small;
		box-shadow: $box-shadow-light;
		margin-bottom: $spacer-x-small;
		background-color: $medium-gray;
	}


}
