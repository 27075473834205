.m-edge-band {
	position: relative;
	display: inline-block;
	width: 70px;
	height: 33px;

	img {
		width: 70px;
		height: 33px;
		display: inline-block;
	}

	&__one, &__two, &__three, &__four {
		background-color: transparent;
		position: absolute;
		cursor: pointer;

		&:hover {
			background-color: $gray;
			z-index: 1;
		}

		&--active {
			background-color: $primary;

			&:hover {
				background-color: $primary;
			}
		}
	}

	&__one {
		left: 0;
		top: 0;
		height: 6px;
		width: 100%;
	}

	&__two {
		left: 0;
		top: 0;
		width: 6px;
		height: 100%;
	}

	&__three {
		left: 0;
		bottom: 0;
		height: 6px;
		width: 100%;
	}

	&__four {
		right: 0;
		top: 0;
		width: 6px;
		height: 100%;
	}
}
