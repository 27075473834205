.o-projects {
	display: flex;
	flex-direction: column;
	flex: 1;
	margin: $spacer-2x-small;
	overflow: hidden;

	&__search {
		position: relative;

		.a-card {
			padding-top: $spacer;
			padding-bottom: $spacer;
		}

		.a-icon {
			position: absolute;
			z-index: 1;
			top: 50%;
			left: 35px;
			transform: translateY(-50%);
			font-size: 1.1rem;
		}

		.a-input {
			margin-bottom: 0;

			.a-input__field {
				box-shadow: $box-shadow-inset-large;
				background-color: #877C52;
				padding-left: 40px;
			}
		}
	}

	&__wrapper {
		display: flex;
		min-height: 100%;
		flex: 1;

		.a-card {
			overflow-y: auto;
		}
	}

	.a-card {
		margin-bottom: $spacer-2x-small;
	}

	&__main {
		flex: 0.75;
		min-height: 100%;
		display: flex;
		flex-direction: column;

		> .a-card {
			flex: 1;
		}
	}

	&__side {
		margin-left: $spacer-2x-small;
		flex: 1;
		display: flex;
		flex-direction: column;

		> .a-card:first-of-type {
			flex: 0.6;
		}

		> .a-card:last-of-type {
			flex: 0.4;
		}
	}
}

@media screen and (max-width: 1200px) {
	.o-projects {
		.o-projects__wrapper {
			flex-direction: column;
			overflow: auto;

			.o-projects__main {
				min-height: unset;
				flex: auto;
			}

			.o-projects__side {
				margin-left: 0;

				> .a-card:first-of-type {
					flex: auto;
				}

				> .a-card:last-of-type {
					flex: auto;
				}
			}
		}
	}
}

