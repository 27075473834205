.o-auth {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: $spacer $spacer-2x-small;
	min-height: 100%;
	flex: 1;

	&--big .o-auth__content {
		max-width: 1120px;
		width: 100%;
	}

	&__content {
		max-width: 400px;
		width: 100%;
	}
}

@media screen and (max-height: 800px) {
	.o-auth {
		align-items: flex-start;
	}
}
