.a-boards {
	background-color: $medium-gray;
	padding: $spacer-2x-small;
	border-radius: $border-radius;
	box-shadow: $box-shadow-light;
	margin-bottom: $spacer-x-small;

	&--overview {
		.a-boards__item {
			display: flex;
			align-items: flex-start;

			&:not(:last-of-type) {
				padding-bottom: $spacer-2x-small;
				margin-bottom: $spacer-2x-small;
				border-bottom: 1px solid $gray;
			}
		}
	}

	&--item {
		min-height: 117px;
	}

	&__materials {
		margin-right: $spacer-2x-small;
		max-width: 150px;

		button:not(:last-of-type) {
			margin-bottom: $spacer-2x-small;
		}
	}

	&--loading {
		animation-duration: 1.25s;
		animation-fill-mode: forwards;
		animation-iteration-count: infinite;
		animation-name: placeHolderShimmer;
		animation-timing-function: linear;
		background: linear-gradient(to right, $medium-gray 8%, rgba($gray, 0.6) 18%, $medium-gray 33%);
		background-size: 800px 104px;
	}

	img {
		width: 100%;
		border-radius: $border-radius;
		height: 50px;
		object-fit: cover;
		display: inline-block;
		background-color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $medium-gray;
		margin-bottom: $spacer-x-small;
	}

	&__info {
		p {
			margin: 0;
		}
	}

	&__item {
		font-size: 12px;
		display: flex;
		align-items: center;

		&:not(:last-child) {
			margin-bottom: $spacer-3x-small;
		}

		button {
			min-height: 25px;
			padding: $spacer-2x-small;
			border-radius: $border-radius;
			background-color: $input-background;
			display: inline-block;
			color: $lighter-gray;
			width: 100%;
			box-shadow: $box-shadow-light;
			border: none;
			text-align: left;
			font-size: 12px;
			font-weight: 300;
			cursor: pointer;
			font-family: 'Roboto', sans-serif;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;

			&:hover {
				outline: 1px solid $gray;
			}
		}
	}

	&__item--full-width {
		button {
			width: 100%;
			min-width: none;
			max-width: none;

			@media screen and (max-width: 1100px) {
				max-width: 280px;
			}
		}
	}
}
