@font-face {
    font-family: 'Calibri';
    src: url('../../fonts/calibri/Calibri-Light.eot');
    src: url('../../fonts/calibri/Calibri-Light.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/calibri/Calibri-Light.woff2') format('woff2'),
        url('../../fonts/calibri/Calibri-Light.woff') format('woff'),
        url('../../fonts/calibri/Calibri-Light.ttf') format('truetype'),
        url('../../fonts/calibri/Calibri-Light.svg#Calibri-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calibri';
    src: url('../../fonts/calibri/calibri.eot');
    src: url('../../fonts/calibri/calibri.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/calibri/Calibri.woff2') format('woff2'),
        url('../../fonts/calibri/calibri.woff') format('woff'),
        url('../../fonts/calibri/calibri.ttf') format('truetype'),
        url('../../fonts/calibri/calibri.svg#Calibri') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('../../fonts/roboto/Roboto-Regular.eot');
	src: url('../../fonts/calibri/calibri.eot?#iefix') format('embedded-opentype'),
	     url('../../fonts/roboto/Roboto-Regular.woff2') format('woff2'),
	     url('../../fonts/roboto/Roboto-Regular.woff') format('woff'),
         url("../../fonts/roboto/Roboto-Regular.ttf") format('truetype'),
         url("../../fonts/roboto/Roboto-Regular.svg#RobotoRegular") format('svg');
    font-weight: normal;
	font-style: normal;
	font-display: swap;
}
