html,
body {
    height: 100%;
}

.u-root {
	display: flex;
	flex: 1;
	min-height: 100vh;
	width: 100%;

	ng-component {
		flex: 1;
		display: flex;
	}
}

.ng-scroll-content {
	padding: 1px;
}

.overlay-container .toast-container .ngx-toastr {
	width: 500px;
	box-shadow: $box-shadow;
}

body {
	color: $lighter-gray;
    margin: 0;
    font-family: 'Roboto', "Helvetica Neue", sans-serif;
	font-weight: 300;
	background-color: $darker-gray;
}

a.a-mail {
	text-transform: none;
	text-decoration: underline;
	font-weight: 300;
	color: $lighter-gray;

    &:hover {
		color: $primary;
		text-decoration: none;
    }
}

a {
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 300;
	color: $lighter-gray;

    &:hover {
		color: $primary;
		text-decoration: none;
    }
}

* {
	box-sizing: border-box;
}

.mat-mdc-unelevated-button.mat-primary,
.mat-mdc-raised-button.mat-primary,
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
    text-transform: uppercase;
    font-size: 12px;
    background-color:  var(--background);;

    &:hover {
        background-color: var(--yellow);
    }
}

.mat-toolbar.mat-primary {
    // background-color: var(--black);
    // color: white
}

.mat-drawer-container {
    background-color: #fff;
}

@mixin md-icon-size($size: 24px) {
    font-size: $size;
    height: $size;
    width: $size;
    line-height: $size;
}

.mat-mdc-card.matcard-stripes {
    padding: 40px 10px 40px;

    &:after,
    &:before {
        content: "";
        position: absolute;
        bottom: 10%;
        display: block;
        height: 1px;
    }

    &:after {
        right: 0;
        width: 70%;
        background-color: var(--yellow);
    }

    &:before {
        left: 0;
        width: 30%;
        background-color:  var(--background);;
    }
}

h3.card-title {
    margin-bottom: 25px;
    font-weight: 300;
    font-size: 20px;
    line-height: 22px;
    color: #595959;
}

p.card-text {
    margin-bottom: 50px;
    font-weight: 300;
    font-size: 17px;
    line-height: 30px;
}

.btn {
    &.align-left {
        padding-left: 0;
    }
}

.mat-mdc-card.form-container {
    min-width: 300px;
    margin: 10% auto;
    padding: 65px 35px;
}

p.error-message {
    display: block;
    margin-bottom: 45px;
    padding: 10px 25px;
    border: 1px solid #f44336;
    border-radius: 5px;
    color: #f44336;
    text-align: center;
}

.loading-spinner {
    margin: 20px auto;
}

.sidenav {
    height: fit-content;
}

//MARGINS
.m-xxs {
    margin: 2px 4px;
}

.m {
    margin: 15px;
}

.m-xs {
    margin: 5px;
}

.m-sm {
    margin: 10px;
}

.m {
    margin: 15px;
}

.m-md {
    margin: 20px;
}

.m-lg {
    margin: 30px;
}

.m-xl {
    margin: 50px;
}

.m-n {
    margin: 0 !important;
}

.m-l-none {
    margin-left: 0;
}

.m-l-xs {
    margin-left: 5px;
}

.m-l-sm {
    margin-left: 10px;
}

.m-l {
    margin-left: 15px;
}

.m-l-md {
    margin-left: 20px;
}

.m-l-lg {
    margin-left: 30px;
}

.m-l-xl {
    margin-left: 40px;
}

.m-t-none {
    margin-top: 0 !important;
}

.m-t-xxs {
    margin-top: 1px;
}

.m-t-xs {
    margin-top: 5px;
}

.m-t-sm {
    margin-top: 10px;
}

.m-t {
    margin-top: 15px;
}

.m-t-md {
    margin-top: 20px;
}

.m-t-lg {
    margin-top: 30px;
}

.m-t-xl {
    margin-top: 50px;
}

.m-r-none {
    margin-right: 0;
}

.m-r-xxs {
    margin-right: 1px;
}

.m-r-xs {
    margin-right: 5px;
}

.m-r-sm {
    margin-right: 10px;
}

.m-r {
    margin-right: 15px;
}

.m-r-md {
    margin-right: 20px;
}

.m-r-lg {
    margin-right: 30px;
}

.m-r-xl {
    margin-right: 40px;
}

.m-b-none {
    margin-bottom: 0;
}

.m-b-xxs {
    margin-bottom: 1px;
}

.m-b-xs {
    margin-bottom: 5px;
}

.m-b-sm {
    margin-bottom: 10px;
}

.m-b {
    margin-bottom: 15px;
}

.m-b-md {
    margin-bottom: 20px;
}

.m-b-lg {
    margin-bottom: 30px;
}

.m-b-xl {
    margin-bottom: 40px;
}

.search {
    margin: 50px 30px 30px 0;

    mat-form-field {
        width: 400px;
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-flex {
            border-radius: 5px;
            padding: 3px;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-underline {
            display: none;
        }
    }

    mat-icon {
        position: relative;
        top: 6px;
        font-size: 25px !important;
        left: 10px;
        width: 40px;
        color: var(--gray);
    }
}

//TABLES
.table-container {
    margin: 20px;

    .table {
        &__header-row {
            background-color: #f2f2f2;
            height: 32px;

            th {
                font-size: 13px;
                color: var(--black);

            }
        }

        &__items-row {
            height: 32px;

            td {
                padding-right: 80px;

                &.center {
                    text-align: center;
                }

                &.action-icon {
                    padding-right: 0px;
                }

                mat-icon:hover {
                    cursor: pointer;
                }
            }

            &:hover {
                background-color: #f2f2f2;
            }
        }
    }
}

.a-clickable {
	cursor: pointer;
}

//MAT-NAV-LIST
.main-menu {
    &__item {
        box-sizing: border-box;
        padding: 12px 10px 12px 5px !important;
        font-size: 15px !important;
        font-weight: 300;
        color: var(--gray) !important;
        background-color: #fafafa;
        border-bottom: 1px solid #e1e1e1;

        &:hover {
            color: var(--black);
            background-color:  var(--background);;
        }

        &.active {
            color: #e8c500 !important;
            background-color: rgba(255, 255, 0, 0.2) !important;
        }
    }

    &__icon {
        margin-right: 10px;
        font-size: 20px;
    }

    .title {
        text-transform: uppercase;
        pointer-events: none;
        cursor: default;
    }
}

.mat-mdc-list-base {
    padding-top: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
.mat-list-item-content {
    height: auto;
}

.mat-mdc-list-base .mat-mdc-list-item,
.mat-list-base .mat-list-option {
    height: fit-content !important;
}

//MAT-DIALOG
.client-form-dialog .mat-mdc-dialog-container {
    border-top: 25px solid white;
    border-bottom: 25px solid white;
}

//MAT-SIDENAV
mat-sidenav-container {
    height: calc(100vh - 50px);
}

//TOOLBAR
.toolbar {
    display: block;
    background-color: #fff;
    border-bottom: 1px solid  var(--background);;

    &__title {
        display: inline-block;
        margin-right: 50px;
        font-size: 15px;
        text-transform: uppercase;
        font-weight: 300;
    }
}

//FORMS
.form {
    &__section {
        margin-bottom: 50px;
    }
}

p.form__title {
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 300;
}

p.form__subtitle {
    margin-bottom: 30px;
    font-size: 22px;
    font-weight: 500;
}

//SNACKBAR
.mat-mdc-simple-snack-bar {
    display: inline-block !important;
}

.mat-mdc-snack-bar-container {
    text-align: center;
    color: white;

    &.success {
        background-color: #51a351;
    }

    &.error {
        background-color: #cf261d;
    }

    &.info {
        background-color: #3276b1;
    }

    &.warning {
        background-color: #f89406;
    }
}


.shepherd-content {
	background-color: $primary;
}

.shepherd-text {
	font-family: 'Roboto', "Helvetica Neue", sans-serif !important;
	padding: 0.75rem 0.5rem !important;
}

.shepherd-arrow::before {
	background: $primary !important;
}

.shepherd-info {
	width: auto !important;
}
