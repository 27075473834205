.a-button {
	box-shadow: $box-shadow-light;
	background-color: #505050;
	border: none;
	border-radius: $border-radius;
	color: $lighter-gray;
	padding: $spacer-small $spacer;
	text-transform: none;
	text-decoration: none;
	cursor: pointer;
	font-size: 15px;
	font-weight: 300;
	font-family: 'Roboto', sans-serif;
	display: inline-block;
	white-space: nowrap;

	app-icon {
		display: inline-block;
	}

	&:hover {
		background-color: rgba($primary, 0.45);
		color: $lighter-gray;
	}

	&.a-button--block {
		width: 100%;
		display: block;
	}

	&.a-button--small {
		padding: $spacer-2x-small $spacer-x-small;

		.a-icon {
			padding: $spacer-5x-small 0;
		}
	}

	&.a-button--x-small {
		padding: 0 $spacer-2x-small;

		.a-icon {
			padding: $spacer-2x-small 0;
		}
	}

	&.a-button--2x-small {
		padding: 0 $spacer-2x-small;

		.a-icon {
			font-size: 0.8rem;
			padding: 2px 0;
		}
	}

	&.a-button--no-padding {
		padding: 0;
	}

	&.a-button--danger {
		background-color: $danger;

		&:hover {
			color: $primary;
		}
	}

	&.a-button--secondary {
		background-color: $gray;
		color: $black;
		font-weight: normal;
		font-family: 'Calibri', sans-serif;
		font-weight: 300;

		// Calibri fix
		padding: 3px $spacer-small 0;

		&:hover, &.a-button--active {
			background-color: $primary;
		}
	}

	&.a-button--success {
		background-color: $success;

		&:hover {
			color: $primary;
		}
	}

	&.a-button--transparent {
		background: none;
		box-shadow: none;
		border: none;

		&:hover, &:hover .a-icon {
			color: $primary;
		}

		&.a-button--danger {
			&:hover .a-icon {
				color: $danger;
			}
		}
	}

	&.a-button--light {
		background-color: $gray;
		color: $darker-gray;

		&:hover, &:hover .a-icon {
			background-color: $primary;
		}
	}

	&.a-button--primary {
		color: $primary;

		&:hover, &:hover .a-icon {
			background-color: $success;
			color: $primary;
		}
	}

	&.a-button--active:not(.a-button--transparent) {
		background-color: $primary;
		color: $darker-gray;

		&:hover, &:hover .a-icon {
			color: $primary;
			background-color: #505050;
		}
	}

	&__icon {
		height: 15px;
		margin-left: $spacer-x-small;
		width: auto;

		&--spin {
			animation: spin 3s linear 0s infinite;
		}
	}

	&--disabled {
		cursor: not-allowed;
		opacity: 0.5;
		filter: grayscale(1);
	}
}

button:disabled {
	cursor: not-allowed;
	pointer-events: none;
	opacity: 0.6;
}
