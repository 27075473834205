.m-modal {
	border: 3px solid $gray;
	box-shadow: $box-shadow;
	background-color: $darker-gray;
	color: $light-gray;
	padding: $spacer-x-small;
	border-radius: $border-radius;
	max-width: 600px;
	width: 100%;

	&--small {
		min-width: 200px;
		width: 200px;

		.m-modal__body {
			padding: $spacer;
		}
	}

	&--large {
		width: 1000px;
		max-width: 1000px;
	}

	&--danger {
		border-color: $danger;

		.a-icon {
			color: $danger;
		}
	}

	&--warning {
		border-color: $warning;

		.a-icon {
			color: $warning;
		}
	}

	&--primary {
		border-color: $primary;

		.a-icon {
			color: $primary;
		}
	}

	&__header {
		background-color: $dark-gray;
		box-shadow: $box-shadow;
		color: $white;
		border-radius: $border-radius;
		padding: $spacer $spacer-large;
		font-size: 1.7rem;
		display: flex;
		align-items: center;

		.a-icon {
			margin-right: $spacer-small;
			font-size: 20px;
		}
	}

	&__body {
		padding: $spacer-x-large;
	}

	&__footer {
		padding: $spacer;
		text-align: right;
	}
}

.cdk-overlay-backdrop {
	background: rgba(0, 0, 0, 0.5);
	backdrop-filter: saturate(0%);
}
