.m-ribbon {
	height: 160px;
	display: flex;
	position: relative;

	> * {
		display: flex;
	}

	&__header {
		text-align: center;
		font-size: 10px;
		text-transform: uppercase;
	}

	&__nested {
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow: hidden;

		&-content {
			height: 100%;
			display: flex;
			flex-direction: row;
			flex: 1;
			overflow: hidden;
		}
	}

	.a-card {
		height: 100%;
	}
}

.m-ribbon__scroll {
	&.m-ribbon__scroll--left::before {
		opacity: 0;
	}

	&.m-ribbon__scroll--right::after {
		opacity: 0;
	}

	&::before {
		transition: opacity .2s ease;
		pointer-events: none;
		z-index: 5;
		opacity: 1;
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 50px;
		background: linear-gradient(90deg, rgba($darker-gray,1) 0%, rgba($darker-gray,0) 100%);
	}

	&::after {
		transition: opacity .2s ease;
		pointer-events: none;
		z-index: 5;
		opacity: 1;
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		width: 50px;
		background: linear-gradient(270deg, rgba($darker-gray,1) 0%, rgba($darker-gray,0) 100%);
	}
}
