.m-table {
	width: 100%;
    border-collapse: collapse;

	p {
		margin: 0;
		padding: 0;
		font-size: 15px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	thead {
		tr td {
			border-bottom: 1px solid rgba($gray, 0.10);
			padding-bottom: $spacer-x-small;
		}

		h2 {
			margin: 0;
			padding: 0;
			font-size: 1.6rem;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		h3 {
			margin: 0;
			padding: 0;
			font-size: 20px;
		}
	}

	tbody tr td {
		padding: $spacer-2x-small $spacer-x-small;
	}

	&__row {
		&.m-table__row--primary p {
			font-size: 16px;
		}

		&.m-table__row--secondary {
			p {
				color: $light-gray
			}

			td:not(:first-child) {
				background-color: $input-background;
				box-shadow: $box-shadow-light;
			}
		}

		&.m-table__row--secondary.m-table__row--secondary-first td:nth-child(2) {
			border-top-left-radius: $border-radius;
		}

		&.m-table__row--secondary.m-table__row--secondary-last td:nth-child(2) {
			border-bottom-left-radius: $border-radius;
		}

		&.m-table__row--secondary.m-table__row--secondary-first td:nth-last-child(1) {
			border-top-right-radius: $border-radius;
		}

		&.m-table__row--secondary.m-table__row--secondary-last td:nth-last-child(1) {
			border-bottom-right-radius: $border-radius;
		}
	}
}
