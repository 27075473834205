.m-drawing {
	&__page {
		position: relative;

		canvas {
			display: block;
		}
	}

	&__drawing {
		z-index: 5;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	&__comments {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	&__comment {
		position: absolute;
		color: red;
		background-color: blue;
	}
}
