$black: #000000;
$darker-gray: #242424;
$dark-gray: #333333;
$medium-gray: #504F4F;
$search-gray: #878787;
$gray: #707070;
$light-gray: #BBBBBB;
$lighter-gray: #E3E3E3;
$white: #FFFFFF;

$primary: #D4B643;
$primary-dark: #9E6E3D;

$success: #3A754E;
$warning: #D4B643;
$danger: #9e3d3d;
$danger-light: #ff6565;

$input-background: #3D3D3D;
$light-input-background: #414141;
