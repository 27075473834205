.o-terms-page {
	padding: $spacer-large;

	.a-general-terms {
		margin-top: $spacer-x-small;
		.a-general-terms-header {
			margin-bottom: $spacer-x-small;
		}
		.a-general-terms-subheader {
			margin-bottom: $spacer-x-small;
			margin-top: $spacer-x-small;
		}
	}

	.a-general-terms-subheader {
		font-size: 16px;
		color: $primary;
		margin: 0;
		padding: 0;
	}
	.a-general-terms-text {
		color: $light-gray;
		font-size: 16px;
		font-weight: 300;
		line-height: 1.5;

		ul {
		    li {
				color: $light-gray;
				font-size: 16px;
				font-weight: 300;
				line-height: 1.5;
		   }
		}
	}

	h2 {
		margin: 0;
		padding: 0;
		font-size: 1.6rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}
