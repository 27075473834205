.m-panel-connection {
	.a-input {
		display: grid;
		grid-template-columns: 1fr 1fr;
		margin-bottom: 0;
	}

	.a-input__radio {
		font-size: 12px;
	}
}
