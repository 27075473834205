@charset "UTF-8";
@font-face {
  font-family: "kastenman-icon";
  src: url("kastenman-icon.eot?t=1671453243779"); /* IE9*/
  src: url("kastenman-icon.eot?t=1671453243779#iefix") format("embedded-opentype"), url("kastenman-icon.woff2?t=1671453243779") format("woff2"), url("kastenman-icon.woff?t=1671453243779") format("woff"), url("kastenman-icon.ttf?t=1671453243779") format("truetype"), url("kastenman-icon.svg?t=1671453243779#kastenman-icon") format("svg"); /* iOS 4.1- */
}
[class^=kastenman-icon-], [class*=" kastenman-icon-"] {
  font-family: "kastenman-icon" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.kastenman-icon-admin:before {
  content: "\ea01";
}

.kastenman-icon-angle-down:before {
  content: "\ea02";
}

.kastenman-icon-angle-left:before {
  content: "\ea03";
}

.kastenman-icon-angle-right:before {
  content: "\ea04";
}

.kastenman-icon-angle-up:before {
  content: "\ea05";
}

.kastenman-icon-bars:before {
  content: "\ea06";
}

.kastenman-icon-cart:before {
  content: "\ea07";
}

.kastenman-icon-check:before {
  content: "\ea08";
}

.kastenman-icon-configurator:before {
  content: "\ea09";
}

.kastenman-icon-designer:before {
  content: "\ea0a";
}

.kastenman-icon-duplicate:before {
  content: "\ea0b";
}

.kastenman-icon-file-upload:before {
  content: "\ea0c";
}

.kastenman-icon-file:before {
  content: "\ea0d";
}

.kastenman-icon-filter:before {
  content: "\ea0e";
}

.kastenman-icon-fullscreen-off:before {
  content: "\ea0f";
}

.kastenman-icon-fullscreen-on:before {
  content: "\ea10";
}

.kastenman-icon-history:before {
  content: "\ea11";
}

.kastenman-icon-home:before {
  content: "\ea12";
}

.kastenman-icon-locked:before {
  content: "\ea13";
}

.kastenman-icon-materials:before {
  content: "\ea14";
}

.kastenman-icon-pencil:before {
  content: "\ea15";
}

.kastenman-icon-plus:before {
  content: "\ea16";
}

.kastenman-icon-rotate-left:before {
  content: "\ea17";
}

.kastenman-icon-save:before {
  content: "\ea18";
}

.kastenman-icon-search:before {
  content: "\ea19";
}

.kastenman-icon-speech-bubble:before {
  content: "\ea1a";
}

.kastenman-icon-spinner:before {
  content: "\ea1b";
}

.kastenman-icon-status:before {
  content: "\ea1c";
}

.kastenman-icon-trash:before {
  content: "\ea1d";
}

.kastenman-icon-truck:before {
  content: "\ea1e";
}

.kastenman-icon-user-add:before {
  content: "\ea1f";
}

.kastenman-icon-user-remove:before {
  content: "\ea20";
}

.kastenman-icon-user:before {
  content: "\ea21";
}

.kastenman-icon-wood:before {
  content: "\ea22";
}

.kastenman-icon-worker:before {
  content: "\ea23";
}

.kastenman-icon-x:before {
  content: "\ea24";
}

.u-row {
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.u-col,
.u-col-1,
.u-col-2,
.u-col-3,
.u-col-4,
.u-col-5,
.u-col-6,
.u-col-7,
.u-col-8,
.u-col-9,
.u-col-10,
.u-col-11,
.u-col-12,
.u-col-offset-0,
.u-col-offset-1,
.u-col-offset-2,
.u-col-offset-3,
.u-col-offset-4,
.u-col-offset-5,
.u-col-offset-6,
.u-col-offset-7,
.u-col-offset-8,
.u-col-offset-9,
.u-col-offset-10,
.u-col-offset-11,
.u-col-offset-12 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.u-col {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%;
}

.u-col-1 {
  -ms-flex-preferred-size: 8.33333333%;
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}

.u-col-2 {
  -ms-flex-preferred-size: 16.66666667%;
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}

.u-col-3 {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%;
}

.u-col-4 {
  -ms-flex-preferred-size: 33.33333333%;
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}

.u-col-5 {
  -ms-flex-preferred-size: 41.66666667%;
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}

.u-col-6 {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
}

.u-col-7 {
  -ms-flex-preferred-size: 58.33333333%;
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}

.u-col-8 {
  -ms-flex-preferred-size: 66.66666667%;
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}

.u-col-9 {
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%;
}

.u-col-10 {
  -ms-flex-preferred-size: 83.33333333%;
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}

.u-col-11 {
  -ms-flex-preferred-size: 91.66666667%;
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}

.u-col-12 {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
}

.u-wrapper {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex: 1;
}
.u-wrapper main {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.u-content {
  overflow-y: auto;
  flex: 1;
  display: flex;
}
.u-content > *:not(router-outlet) {
  display: flex;
  flex: 1;
}

@font-face {
  font-family: "Calibri";
  src: url("../../fonts/calibri/Calibri-Light.eot");
  src: url("../../fonts/calibri/Calibri-Light.eot?#iefix") format("embedded-opentype"), url("../../fonts/calibri/Calibri-Light.woff2") format("woff2"), url("../../fonts/calibri/Calibri-Light.woff") format("woff"), url("../../fonts/calibri/Calibri-Light.ttf") format("truetype"), url("../../fonts/calibri/Calibri-Light.svg#Calibri-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Calibri";
  src: url("../../fonts/calibri/calibri.eot");
  src: url("../../fonts/calibri/calibri.eot?#iefix") format("embedded-opentype"), url("../../fonts/calibri/Calibri.woff2") format("woff2"), url("../../fonts/calibri/calibri.woff") format("woff"), url("../../fonts/calibri/calibri.ttf") format("truetype"), url("../../fonts/calibri/calibri.svg#Calibri") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../../fonts/roboto/Roboto-Regular.eot");
  src: url("../../fonts/calibri/calibri.eot?#iefix") format("embedded-opentype"), url("../../fonts/roboto/Roboto-Regular.woff2") format("woff2"), url("../../fonts/roboto/Roboto-Regular.woff") format("woff"), url("../../fonts/roboto/Roboto-Regular.ttf") format("truetype"), url("../../fonts/roboto/Roboto-Regular.svg#RobotoRegular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.u-flex {
  display: flex;
  align-items: flex-start;
}
.u-flex.u-flex--center {
  justify-content: center;
  align-items: center;
}
.u-flex.u-flex--space-between {
  justify-content: space-between;
}
.u-flex.u-flex--end {
  justify-content: flex-end;
}
.u-flex .u-flex--1 {
  flex: 1;
}

.u-hidden {
  display: none;
}

.u-full-width {
  width: 100%;
}

.u-margin-top-2x-large {
  margin-top: 3rem;
}

.u-margin-top-x-large {
  margin-top: 2rem;
}

.u-margin-top-lg {
  margin-top: 1.5rem;
}

.u-margin-top-none {
  margin-top: 0;
}

.u-margin-top {
  margin-top: 1rem;
}

.u-margin-top-sm {
  margin-top: 0.6666666667rem;
}

.u-margin-top-x-small {
  margin-top: 0.5rem;
}

.u-margin-top-2x-small {
  margin-top: 0.3333333333rem;
}

.u-margin-bottom-lg {
  margin-bottom: 1.5rem;
}

.u-margin-bottom-none {
  margin-bottom: 0;
}

.u-margin-bottom-x-large {
  margin-bottom: 2rem;
}

.u-margin-bottom {
  margin-bottom: 1rem;
}

.u-margin-bottom-sm {
  margin-bottom: 0.6666666667rem;
}

.u-margin-bottom-x-small {
  margin-bottom: 0.5rem;
}

.u-margin-bottom-2x-small {
  margin-bottom: 0.3333333333rem;
}

.u-margin-left-lg {
  margin-left: 1.5rem;
}

.u-margin-left-none {
  margin-left: 0;
}

.u-margin-left {
  margin-left: 1rem;
}

.u-margin-left-auto {
  margin-left: auto;
}

.u-margin-left-sm {
  margin-left: 0.6666666667rem;
}

.u-margin-left-x-small {
  margin-left: 0.5rem;
}

.u-margin-left-2x-small {
  margin-left: 0.3333333333rem;
}

.u-margin-right-lg {
  margin-right: 1.5rem;
}

.u-margin-right {
  margin-right: 1rem;
}

.u-margin-right-auto {
  margin-right: auto;
}

.u-margin-right-sm {
  margin-right: 0.6666666667rem;
}

.u-margin-right-x-small {
  margin-right: 0.5rem;
}

.u-margin-right-2x-small {
  margin-right: 0.3333333333rem;
}

.u-margin-right-none {
  margin-right: 0;
}

h1, h2, h3, h4, h5, h6, p {
  font-weight: 300;
  line-height: 1.5;
}

p {
  font-size: 14px;
}

.u-text-center {
  text-align: center;
}

.u-text-right {
  text-align: right;
}

.u-text-highlight {
  color: #D4B643;
}

.u-text-danger {
  color: #9e3d3d;
}

.u-text-warning {
  color: #D4B643;
}

.u-text-light {
  color: #707070;
}

.u-text-white {
  color: #FFFFFF;
}

.u-text-muted {
  color: #BBBBBB;
}

.u-text-number {
  font-family: "Calibri", sans-serif;
  font-weight: 300;
}

.u-text--x-small {
  color: #BBBBBB;
  font-size: 10px !important;
  line-height: 0.6;
}

.u-text--small {
  color: #BBBBBB;
  font-size: 12px !important;
  line-height: 0.6;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.o-header {
  background-color: #333333;
  color: #E3E3E3;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "content logo user-info";
  padding: 0.6666666667rem 1rem;
  height: 46px;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.16);
  position: relative;
  z-index: 20;
}
.o-header__content {
  grid-area: "content";
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.o-header__item {
  display: inline-block;
  padding: 0 1rem;
  height: 20px;
  position: relative;
}
.o-header__item .a-icon {
  font-size: 1.2rem;
  line-height: 1.1;
}
.o-header__item:first-of-type {
  padding-left: 0;
}
.o-header__item:not(:last-of-type)::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  height: 20px;
  background-color: #707070;
}
.o-header__logo {
  grid-area: "logo";
  display: flex;
  align-items: center;
  justify-content: center;
}
.o-header__logo img {
  width: 150px;
  height: auto;
}
.o-header__user-info {
  grid-area: "user-info";
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.o-auth {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0.3333333333rem;
  min-height: 100%;
  flex: 1;
}
.o-auth--big .o-auth__content {
  max-width: 1120px;
  width: 100%;
}
.o-auth__content {
  max-width: 400px;
  width: 100%;
}

@media screen and (max-height: 800px) {
  .o-auth {
    align-items: flex-start;
  }
}
.o-projects {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0.3333333333rem;
  overflow: hidden;
}
.o-projects__search {
  position: relative;
}
.o-projects__search .a-card {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.o-projects__search .a-icon {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 35px;
  transform: translateY(-50%);
  font-size: 1.1rem;
}
.o-projects__search .a-input {
  margin-bottom: 0;
}
.o-projects__search .a-input .a-input__field {
  box-shadow: inset 3px 3px 6px 0px rgba(0, 0, 0, 0.16);
  background-color: #877C52;
  padding-left: 40px;
}
.o-projects__wrapper {
  display: flex;
  min-height: 100%;
  flex: 1;
}
.o-projects__wrapper .a-card {
  overflow-y: auto;
}
.o-projects .a-card {
  margin-bottom: 0.3333333333rem;
}
.o-projects__main {
  flex: 0.75;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.o-projects__main > .a-card {
  flex: 1;
}
.o-projects__side {
  margin-left: 0.3333333333rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.o-projects__side > .a-card:first-of-type {
  flex: 0.6;
}
.o-projects__side > .a-card:last-of-type {
  flex: 0.4;
}

@media screen and (max-width: 1200px) {
  .o-projects .o-projects__wrapper {
    flex-direction: column;
    overflow: auto;
  }
  .o-projects .o-projects__wrapper .o-projects__main {
    min-height: unset;
    flex: auto;
  }
  .o-projects .o-projects__wrapper .o-projects__side {
    margin-left: 0;
  }
  .o-projects .o-projects__wrapper .o-projects__side > .a-card:first-of-type {
    flex: auto;
  }
  .o-projects .o-projects__wrapper .o-projects__side > .a-card:last-of-type {
    flex: auto;
  }
}
.o-configurator {
  display: flex;
  flex: 1;
  padding: 0.3333333333rem;
  padding-top: 0;
  width: calc(100vw - 26px);
}
.o-configurator__container {
  display: flex;
  flex: 1;
  padding-top: 0;
  height: calc(100vh - 60px);
}
.o-configurator__navigation {
  min-width: 360px;
  width: 360px;
  padding-right: 0.3333333333rem;
}
.o-configurator__designer {
  display: flex;
  flex: 1;
  min-width: 360px;
  width: 100%;
  overflow: hidden;
}
.o-configurator__designer > * {
  overflow: hidden;
  display: flex;
  flex: 1;
}
.o-configurator__configuration {
  min-width: 430px;
  width: 430px;
  padding-left: 0.3333333333rem;
  display: flex;
  height: calc(100vh - 60px);
}
@media screen and (max-width: 1100px) {
  .o-configurator__configuration {
    min-width: 360px;
    width: 360px;
  }
}
.o-configurator__configuration > * {
  width: 100%;
}
.o-configurator__configuration > * > * > * {
  overflow-y: auto;
  height: 100%;
  max-height: calc(100vh - 60px);
}
.o-configurator__configuration > * > * > *::-webkit-scrollbar-thumb {
  display: none;
}
.o-configurator__configuration > * > * > *::-webkit-scrollbar {
  display: none;
}
.o-configurator__wrapper {
  display: flex;
  flex-wrap: wrap;
}
.o-configurator > ng-component {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.o-navigation-panel {
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
}
.o-navigation-panel__navigator {
  flex: 1;
  overflow-y: auto;
}
.o-navigation-panel__navigator > .a-card {
  height: 100%;
}
.o-navigation-panel .a-boards {
  margin-left: 3rem !important;
}

.o-material {
  background-color: #333333;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.o-material__type {
  margin-top: 1rem;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.16);
}
.o-material__preview-image {
  height: 15px;
  width: 25px;
  border-radius: 3px;
}
.o-material__image {
  height: 300px;
  margin-bottom: 1rem;
  background-size: cover;
}
.o-material__content {
  display: flex;
  flex: 1;
  padding: 0.5rem;
}
.o-material__detail {
  align-self: flex-start;
  margin-top: 1rem;
  min-width: 500px;
  width: 500px;
  background-color: #242424;
  border-radius: 3px;
  border: 1px solid #504F4F;
  padding: 1.5rem;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.16);
}
.o-material__detail h2, .o-material__detail h3, .o-material__detail h4 {
  margin: 0.3333333333rem 0;
}
.o-material__detail h3 {
  font-size: 18px;
}
.o-material__detail h4 {
  font-size: 14px;
}
.o-material__detail small {
  color: #707070;
  font-size: 12px;
}
.o-material__content {
  max-height: calc(100vh - 105px);
}
.o-material__list {
  flex: 1;
  margin-left: 1.5rem;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.o-catalog {
  overflow: auto;
}
.o-catalog .o-catalog__title > h3 {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.o-catalog__filters {
  min-width: 225px;
  max-width: 225px;
  margin-right: 0.3333333333rem;
}
.o-catalog__filter {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}
.o-catalog__results {
  display: flex;
  flex: 1;
}
.o-catalog__results > .a-card {
  width: 100%;
}
.o-catalog__grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 0.3333333333rem;
}
.o-catalog__grid > .a-card {
  width: 100%;
  overflow: hidden;
}
.o-catalog--create {
  display: flex;
  flex-direction: column;
  max-width: 500px;
}
.o-catalog--create .a-input.a-input--horizontal {
  margin-bottom: 0;
}
.o-catalog--create .a-input .a-input__label {
  margin-bottom: 0.5rem;
}
.o-catalog__preview {
  flex: 1;
  max-height: 500px;
}

.o-hardware {
  flex-direction: column;
  overflow: auto;
}
.o-hardware__content {
  display: flex;
  flex: 1;
}
.o-hardware .o-hardware__title > h3 {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.o-hardware__filters {
  min-width: 225px;
  max-width: 225px;
  margin-right: 0.3333333333rem;
}
.o-hardware__filter {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}
.o-hardware__results {
  display: flex;
  flex: 1;
}
.o-hardware__results > .a-card {
  width: 100%;
}
.o-hardware__grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 0.3333333333rem;
}
@media screen and (max-width: 1500px) {
  .o-hardware__grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 1300px) {
  .o-hardware__grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 1100px) {
  .o-hardware__grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 900px) {
  .o-hardware__grid {
    grid-template-columns: 1fr;
  }
}
.o-hardware__grid > .a-card {
  width: 100%;
  overflow: hidden;
}
.o-hardware--create {
  display: flex;
  flex-direction: column;
  max-width: 500px;
}
.o-hardware--create .a-input.a-input--horizontal {
  margin-bottom: 0;
}
.o-hardware--create .a-input .a-input__label {
  margin-bottom: 0.5rem;
}
.o-hardware__preview {
  flex: 1;
  max-height: 500px;
}

.o-filter-form {
  position: sticky;
  top: 0;
  background-color: #333333;
  z-index: 1;
}

.o-terms-page {
  padding: 1.5rem;
}
.o-terms-page .a-general-terms {
  margin-top: 0.5rem;
}
.o-terms-page .a-general-terms .a-general-terms-header {
  margin-bottom: 0.5rem;
}
.o-terms-page .a-general-terms .a-general-terms-subheader {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.o-terms-page .a-general-terms-subheader {
  font-size: 16px;
  color: #D4B643;
  margin: 0;
  padding: 0;
}
.o-terms-page .a-general-terms-text {
  color: #BBBBBB;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
}
.o-terms-page .a-general-terms-text ul li {
  color: #BBBBBB;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
}
.o-terms-page h2 {
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.m-table {
  width: 100%;
  border-collapse: collapse;
}
.m-table p {
  margin: 0;
  padding: 0;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.m-table thead tr td {
  border-bottom: 1px solid rgba(112, 112, 112, 0.1);
  padding-bottom: 0.5rem;
}
.m-table thead h2 {
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.m-table thead h3 {
  margin: 0;
  padding: 0;
  font-size: 20px;
}
.m-table tbody tr td {
  padding: 0.3333333333rem 0.5rem;
}
.m-table__row.m-table__row--primary p {
  font-size: 16px;
}
.m-table__row.m-table__row--secondary p {
  color: #BBBBBB;
}
.m-table__row.m-table__row--secondary td:not(:first-child) {
  background-color: #3D3D3D;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.11);
}
.m-table__row.m-table__row--secondary.m-table__row--secondary-first td:nth-child(2) {
  border-top-left-radius: 3px;
}
.m-table__row.m-table__row--secondary.m-table__row--secondary-last td:nth-child(2) {
  border-bottom-left-radius: 3px;
}
.m-table__row.m-table__row--secondary.m-table__row--secondary-first td:nth-last-child(1) {
  border-top-right-radius: 3px;
}
.m-table__row.m-table__row--secondary.m-table__row--secondary-last td:nth-last-child(1) {
  border-bottom-right-radius: 3px;
}

.m-user-info {
  position: relative;
}
.m-user-info__button {
  background: none;
  padding: 0;
  border: none;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  color: #E3E3E3;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.m-user-info__button .a-icon {
  margin-left: 0.5rem;
  font-size: 1rem;
}
.m-user-info .m-user-info__dropdown {
  position: absolute;
  top: calc(46px - 1rem);
  right: 0.25rem;
  background-color: #707070;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.16);
  text-align: right;
  border-radius: 3px;
  padding: 0.6666666667rem;
  width: 250px;
  z-index: 0;
}
.m-user-info .m-user-info__dropdown ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.m-user-info .m-user-info__dropdown ul a {
  display: inline-block;
  padding: 0.5rem 1rem;
  text-transform: none;
  text-decoration: none;
  color: #FFFFFF;
  font-size: 16px;
  width: 100%;
  border-radius: 3px;
}
.m-user-info .m-user-info__dropdown ul a:hover {
  outline: 1px solid #FFFFFF;
}
.m-user-info__avatar {
  margin-right: 25px;
}
.m-user-info__avatar img {
  width: 100px;
  height: 100px;
}
.m-user-info__name {
  font-weight: 300;
  font-size: 20px;
  line-height: 20px;
}
.m-user-info__email {
  font-weight: 300;
  font-size: 13px;
  line-height: 11px;
}

.signout-btn {
  margin-top: 40px;
}

.m-loading {
  padding: 2rem;
  text-align: center;
  flex: 1;
  width: 100%;
}
.m-loading--center {
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50000;
}
.m-loading .a-icon {
  font-size: 1.2rem;
}
.m-loading--large .a-icon {
  font-size: 3em;
}
.m-loading--xl {
  background-color: white;
}
.m-loading--xl .a-icon {
  color: rgb(32, 32, 32);
  font-size: 50vw;
}

.m-project-header {
  display: flex;
}
.m-project-header__info {
  flex: 1;
}
.m-project-header__info h3 {
  font-size: 20px;
  color: #D4B643;
  margin: 0;
  padding: 0;
}
.m-project-header__name {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.m-project-header__name .a-icon {
  margin-left: 0.6666666667rem;
}
.m-project-header__status {
  flex: 1;
  text-align: right;
}

.m-board-table {
  width: 100%;
  border-collapse: collapse;
  position: relative;
}
.m-board-table.m-board-table--sticky thead {
  position: sticky;
  top: 70px;
}
.m-board-table thead {
  background-color: #333333;
}
.m-board-table thead tr th {
  text-align: left;
  font-weight: 300;
  font-size: 12px;
  padding: 0.3333333333rem 0.3333333333rem 0.5rem 0.3333333333rem;
}
.m-board-table tbody tr {
  cursor: pointer;
  outline: 1px solid transparent;
  border-radius: 3px;
}
.m-board-table tbody tr:hover td, .m-board-table tbody tr.m-board-table__row--active td {
  background-color: #504F4F;
}
.m-board-table tbody tr:hover td:first-child, .m-board-table tbody tr.m-board-table__row--active td:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.m-board-table tbody tr:hover td:last-child, .m-board-table tbody tr.m-board-table__row--active td:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.m-board-table tbody tr td {
  text-align: left;
  font-weight: 300;
  font-size: 14px;
  padding: 0.3333333333rem;
}
.m-board-table__colour {
  width: 10px;
  height: 10px;
  background-color: white;
}
.m-board-table__price {
  height: 5px;
  width: 50px;
  background-color: #707070;
}
.m-board-table__price-inner {
  background-color: #FFFFFF;
  height: 5px;
}

.m-cutting-list-table {
  width: 100%;
  border-collapse: collapse;
  position: relative;
}
.m-cutting-list-table thead {
  position: sticky;
  top: 0;
}
@media screen and (max-width: 1440px) {
  .m-cutting-list-table thead {
    display: none;
  }
}
.m-cutting-list-table thead tr th {
  font-weight: 300;
  font-size: 12px;
  text-align: center;
  padding: 0.3333333333rem 0.3333333333rem 0.5rem 0.3333333333rem;
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}
.m-cutting-list-table tbody tr {
  outline: 1px solid transparent;
  border-radius: 3px;
}
@media screen and (max-width: 1440px) {
  .m-cutting-list-table tbody tr {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    display: grid;
    background-color: #504F4F;
    margin-bottom: 0.3333333333rem;
  }
}
@media screen and (max-width: 1300px) {
  .m-cutting-list-table tbody tr {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    display: grid;
    background-color: #504F4F;
    margin-bottom: 0.3333333333rem;
  }
}
@media screen and (max-width: 1260px) {
  .m-cutting-list-table tbody tr {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.m-cutting-list-table tbody tr td {
  text-align: left;
  font-weight: 300;
  font-size: 14px;
  padding: 0.3333333333rem;
}
.m-cutting-list-table tbody tr td p {
  font-weight: 300;
  font-size: 12px;
  text-align: center;
  min-height: 18px;
}
@media screen and (min-width: 1440px) {
  .m-cutting-list-table tbody tr td p {
    display: none;
  }
}
.m-cutting-list-table__cell {
  padding: 0 0.25rem;
}
.m-cutting-list-table__cell .a-input {
  margin-bottom: 0;
  min-width: 60px;
}
.m-cutting-list-table__actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}
.m-cutting-list-table__material {
  color: #FFFFFF;
  background-color: #707070;
  cursor: pointer;
  border: none;
  width: 100%;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  padding: 0.3333333333rem;
  text-align: left;
  font-weight: 300;
  border-radius: 3px;
  min-width: 100px;
}

.m-edge-band {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 33px;
}
.m-edge-band img {
  width: 70px;
  height: 33px;
  display: inline-block;
}
.m-edge-band__one, .m-edge-band__two, .m-edge-band__three, .m-edge-band__four {
  background-color: transparent;
  position: absolute;
  cursor: pointer;
}
.m-edge-band__one:hover, .m-edge-band__two:hover, .m-edge-band__three:hover, .m-edge-band__four:hover {
  background-color: #707070;
  z-index: 1;
}
.m-edge-band__one--active, .m-edge-band__two--active, .m-edge-band__three--active, .m-edge-band__four--active {
  background-color: #D4B643;
}
.m-edge-band__one--active:hover, .m-edge-band__two--active:hover, .m-edge-band__three--active:hover, .m-edge-band__four--active:hover {
  background-color: #D4B643;
}
.m-edge-band__one {
  left: 0;
  top: 0;
  height: 6px;
  width: 100%;
}
.m-edge-band__two {
  left: 0;
  top: 0;
  width: 6px;
  height: 100%;
}
.m-edge-band__three {
  left: 0;
  bottom: 0;
  height: 6px;
  width: 100%;
}
.m-edge-band__four {
  right: 0;
  top: 0;
  width: 6px;
  height: 100%;
}

.m-edge-band-example {
  display: flex;
  justify-content: center;
}
.m-edge-band-example p {
  margin: 0;
  padding: 0;
  font-weight: normal;
}
.m-edge-band-example__width {
  writing-mode: vertical-rl;
  text-orientation: sideways;
  text-align: center;
  margin-bottom: 25px !important;
  margin-right: 0.3333333333rem !important;
}
.m-edge-band-example__wrapper {
  background-color: #707070;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 0.3333333333rem 0.6666666667rem;
}
.m-edge-band-example__container > p {
  text-align: center;
  margin-top: 0.3333333333rem;
}
.m-edge-band-example__horizontal {
  background-color: #707070;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.m-edge-band-example__horizontal img {
  width: 70px;
  height: 33px;
  display: inline-block;
  margin: 0.3333333333rem 0.6666666667rem;
}

.m-project-navigator__part, .m-project-navigator__item-loading, .m-project-navigator__item, .m-project-navigator__boards, .m-project-navigator__add-cabinetgroup, .m-project-navigator__select-cabinetgroup, .m-project-navigator__select-cabinet {
  border-radius: 3px;
  padding: 0.3333333333rem;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.11);
  margin-bottom: 0.5rem;
}
.m-project-navigator__part {
  background-color: #3D3D3D;
  display: flex;
  cursor: pointer;
}
.m-project-navigator__part:first-of-type {
  margin-top: 1px;
}
.m-project-navigator__part:hover {
  outline: 1px solid #707070;
}
.m-project-navigator__part__button {
  margin-right: 0.5rem;
}
.m-project-navigator__part--active, .m-project-navigator__part--active:hover {
  outline: 1px solid #D4B643;
}
.m-project-navigator__part__info {
  flex: 1;
}
.m-project-navigator__part__info h3 {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #D4B643;
}
.m-project-navigator__part__info p {
  margin: 0.3333333333rem 0 0 0;
  padding: 0;
  font-size: 14px;
}
.m-project-navigator__part__meta {
  width: 50px;
  display: flex;
  align-items: flex-end;
}
.m-project-navigator__part__actions {
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
.m-project-navigator__part__actions p {
  margin: 0.5rem 0 0 0;
  padding: 0;
}
.m-project-navigator__item-loading {
  background: #504F4F;
  margin-left: 1.5rem;
  height: 55px;
  position: relative;
  overflow: hidden;
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #504F4F 8%, rgba(112, 112, 112, 0.6) 18%, #504F4F 33%);
  background-size: 800px 104px;
}
.m-project-navigator__item {
  background-color: #504F4F;
  margin-left: 1.5rem;
  display: flex;
  cursor: pointer;
}
.m-project-navigator__item:hover {
  outline: 1px solid #707070;
}
.m-project-navigator__item--active, .m-project-navigator__item--active:hover {
  outline: 1px solid #D4B643;
}
.m-project-navigator__item--part-active {
  outline: 1px solid #558466;
}
.m-project-navigator__item__button {
  margin-right: 0.5rem;
}
.m-project-navigator__item__info {
  flex: 1;
}
.m-project-navigator__item__info h3 {
  margin: 0.3333333333rem 0 0 0;
  padding: 0;
  font-size: 16px;
  color: #FFFFFF;
}
.m-project-navigator__item__info p {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #BBBBBB;
}
.m-project-navigator__item__meta {
  width: 50px;
  display: flex;
  align-items: flex-end;
}
.m-project-navigator__item__actions {
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
.m-project-navigator__item__actions p {
  margin: 0.5rem 0 0 0;
  padding: 0;
}
.m-project-navigator__add-cabinetgroup {
  background-color: #504F4F;
}
.m-project-navigator__select-cabinetgroup {
  background-color: #504F4F;
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.m-project-navigator__select-cabinetgroup:hover {
  outline: 1px solid #707070;
}
.m-project-navigator__select-cabinetgroup--disabled {
  opacity: 0.6;
  pointer-events: none;
}
.m-project-navigator__select-cabinetgroup p {
  margin: 0 0 0 0.5rem;
}
.m-project-navigator__add-cabinet__wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  margin-bottom: 0.5rem;
  margin-left: 1.5rem;
}
.m-project-navigator__add-item {
  display: flex;
  justify-content: flex-end;
  margin-left: 0.5rem;
}
.m-project-navigator__select-cabinet__wrapper {
  flex: 1;
}
.m-project-navigator__select-cabinet {
  background-color: #504F4F;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.m-project-navigator__select-cabinet:hover {
  outline: 1px solid #707070;
}
.m-project-navigator__select-cabinet p {
  margin: 0 0 0 0.5rem;
}
.m-project-navigator__add-item {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
}

.m-handle-position {
  position: relative;
  height: 290px;
  width: 290px;
  background-color: #333333;
}
.m-handle-position__inner {
  height: 290px;
  width: 290px;
  top: 0;
  left: 0;
  background-color: #333333;
  position: absolute;
}
.m-handle-position__button {
  position: absolute;
  background-color: #BBBBBB;
  border: none;
  width: 40px;
  height: 20px;
  cursor: pointer;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
}
.m-handle-position__button:disabled {
  opacity: 0.3;
}
.m-handle-position__button--selected {
  color: #242424;
  background-color: #D4B643;
}
.m-handle-position__button--vlt, .m-handle-position__button--vlc, .m-handle-position__button--vlb, .m-handle-position__button--vrt, .m-handle-position__button--vrc, .m-handle-position__button--vrb {
  width: 20px;
  height: 40px;
}
.m-handle-position__button--vlt span, .m-handle-position__button--vlc span, .m-handle-position__button--vlb span, .m-handle-position__button--vrt span, .m-handle-position__button--vrc span, .m-handle-position__button--vrb span {
  text-orientation: mixed;
  writing-mode: vertical-rl;
  line-height: 0;
}
.m-handle-position__button--hlt {
  top: 5px;
  left: 35px;
}
.m-handle-position__button--hct {
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
}
.m-handle-position__button--hrt {
  top: 5px;
  right: 35px;
}
.m-handle-position__button--vlt {
  left: 5px;
  top: 35px;
}
.m-handle-position__button--vlc {
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
}
.m-handle-position__button--vlb {
  left: 5px;
  bottom: 35px;
}
.m-handle-position__button--hlb {
  bottom: 5px;
  left: 35px;
}
.m-handle-position__button--hcb {
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
}
.m-handle-position__button--hrb {
  bottom: 5px;
  right: 35px;
}
.m-handle-position__button--vrt {
  right: 5px;
  top: 35px;
}
.m-handle-position__button--vrc {
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}
.m-handle-position__button--vrb {
  right: 5px;
  bottom: 35px;
}
.m-handle-position__button--vcc {
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-100%);
  width: 20px;
  height: 40px;
}
.m-handle-position__button--vcc span {
  text-orientation: mixed;
  writing-mode: vertical-rl;
  line-height: 0;
}
.m-handle-position__button--hcc {
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(10%);
}

.m-handle-selection__variants {
  margin-left: 2px;
  margin-bottom: 1rem;
}

.m-card-tabs {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-end;
}
.m-card-tabs li {
  display: inline-block;
}
.m-card-tabs__item {
  background-color: #333333;
  border: none;
  font-size: 14px;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  color: #BBBBBB;
  padding: 0.3333333333rem 0.5rem;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.11);
  cursor: pointer;
}
.m-card-tabs__item:hover {
  color: #D4B643;
}
.m-card-tabs__item--active {
  position: relative;
  font-size: 16px;
  color: #D4B643;
  padding: 0.5rem;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.16);
  z-index: 1;
}

.m-status-history {
  position: relative;
}
.m-status-history::after {
  content: "";
  position: absolute;
  left: 0;
  top: -10px;
  height: calc(100% + 20px);
  width: 5px;
  background-color: #707070;
  border-radius: 5px;
}
.m-status-history__item {
  position: relative;
  left: -3px;
  display: flex;
  align-items: center;
  z-index: 1;
  padding-left: 1.5rem;
}
.m-status-history__item::after {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 7px;
  height: 7px;
  border: 2px solid #D4B643;
  background-color: #333333;
  border-radius: 10px;
}
.m-status-history__item:not(:last-child) {
  margin-bottom: 1.5rem;
}
.m-status-history .m-status-history__date {
  margin-left: 1rem;
  font-size: 0.8rem;
  color: #707070;
}

.m-catalog-item h4 {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}
.m-catalog-item h4 span {
  flex: 1;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.m-catalog-item__favourite .a-button {
  font-size: 1.1rem;
}
.m-catalog-item .a-button--transparent {
  color: #707070;
}
.m-catalog-item__preview {
  height: 200px;
  position: relative;
  margin: 0.5rem 0;
}
.m-catalog-item__edit {
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
}
.m-catalog-item__status {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
}
.m-catalog-item__delete {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
.m-catalog-item__add {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
}

.m-hardware-item h4 {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}
.m-hardware-item h4 span {
  flex: 1;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.m-hardware-item__favourite .a-button {
  font-size: 1.1rem;
}
.m-hardware-item .m-hardware-item__preview {
  border-radius: 3px;
  overflow: hidden;
}
.m-hardware-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.m-hardware-item .a-button--transparent {
  color: #707070;
}
.m-hardware-item__preview {
  height: 200px;
  position: relative;
  margin: 0.5rem 0;
}
.m-hardware-item__edit {
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
}
.m-hardware-item__status {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
}
.m-hardware-item__delete {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
.m-hardware-item__add {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
}
.m-hardware-item__image-navigation {
  position: absolute;
  bottom: 0.5rem;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
}
.m-hardware-item__image-navigation ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}
.m-hardware-item__image-navigation-item {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #BBBBBB;
  border: 1px solid transparent;
  cursor: pointer;
  margin: 0 0.25rem;
}
.m-hardware-item__image-navigation-item--active {
  background-color: #D4B643;
}
.m-hardware-item__variants {
  margin-top: 0.6666666667rem;
}
.m-hardware-item__variant {
  border: none;
  display: inline-block;
  height: 20px;
  width: 30px;
  border-radius: 3px;
  margin-right: 0.5rem;
  outline: 2px solid transparent;
  border: 1px solid transparent;
  cursor: pointer;
}
.m-hardware-item__variant--selected, .m-hardware-item__variant--selected:focus {
  outline: 2px solid #D4B643 !important;
  border: 1px solid #3D3D3D;
}
.m-hardware-item__variant--white {
  background-color: white;
}
.m-hardware-item__variant--black {
  background-color: black;
}
.m-hardware-item__variant--other {
  background-color: black;
}
.m-hardware-item__variant--grey {
  background-color: grey;
}
.m-hardware-item__variant--messing {
  background-color: #D5BC76;
}
.m-hardware-item__variant--brown {
  background-color: #884D19;
}
.m-hardware-item__variant--dark_brown {
  background-color: rgb(95, 9, 9);
}
.m-hardware-item__variant--beige {
  background-color: beige;
}
.m-hardware-item__variant--green {
  background-color: green;
}
.m-hardware-item__variant--red {
  background-color: red;
}
.m-hardware-item__variant--aluminium {
  background-color: #A8ACB6;
}
.m-hardware-item__variant--copper {
  background-color: #A8723E;
}
.m-hardware-item__variant--bronze {
  background-color: #BC7F40;
}
.m-hardware-item__variant--gold {
  background-color: gold;
}
.m-hardware-item__variant--silver {
  background-color: silver;
}
.m-hardware-item__variant--nickel {
  background-color: #9EA4AB;
}
.m-hardware-item__variant--metal {
  background-color: #999BA0;
}
.m-hardware-item__variant--inox {
  background-color: #CFCFCC;
}

.m-empty-state {
  padding: 2rem;
  text-align: center;
  flex: 1;
  width: 100%;
}

.m-customisation {
  margin-top: 0.6666666667rem;
}
.m-customisation--pending .m-customisation__selector--selected, .m-customisation--pending .m-customisation__response-actions p {
  background-color: #D4B643;
}
.m-customisation--pending .a-tag {
  background-color: #D49043;
}
.m-customisation--pending .m-customisation__input .NgxEditor {
  border-color: #D4B643;
}
.m-customisation__header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.m-customisation__header .a-tag {
  margin-bottom: 0.25rem;
}
.m-customisation__selector button {
  border: none;
  background-color: #BBBBBB;
  font-family: "Calibri", sans-serif;
  padding: 0.5rem 0.5rem;
  font-size: 1rem;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  margin-right: 0.5rem;
  cursor: pointer;
}
.m-customisation__selector button:hover {
  color: #242424;
}
.m-customisation__selector button.m-customisation__selector--selected {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding-bottom: 2rem;
}
.m-customisation__selector button.m-customisation__selector--button .a-icon {
  font-size: 0.7rem;
}
.m-customisation__input.m-customisation__input--border-fix .NgxEditor {
  border-top-left-radius: 0;
}
.m-customisation__input .NgxEditor {
  width: 100%;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  background-color: #BBBBBB;
  resize: vertical;
  border: 2px solid #707070;
  box-shadow: inset 3px 3px 4px 0px rgba(0, 0, 0, 0.11);
  font-size: 14px;
  font-weight: 300;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
}
.m-customisation__input .NgxEditor p {
  margin-bottom: 0;
}
.m-customisation__input .NgxEditor .ProseMirror {
  padding: 0.5rem;
}
.m-customisation__input :focus {
  outline: none;
}
.m-customisation__file {
  display: flex;
  align-items: center;
  color: #BBBBBB;
  font-size: 0.9rem;
}
.m-customisation__files > .a-button .a-icon {
  color: #BBBBBB;
}
.m-customisation__response-actions {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
}
.m-customisation__response-actions p {
  color: #242424;
  background-color: #707070;
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.16);
  font-family: "Calibri", sans-serif;
  font-weight: normal;
}

.m-outline-selector {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(5, 136px);
}
.m-outline-selector__button {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.11);
  background-color: #505050;
  border: none;
  border-radius: 3px;
  height: 128px;
  margin-right: 0.5rem;
  display: inline-block;
  margin-bottom: 0.5rem;
  cursor: pointer;
}
.m-outline-selector__button:hover {
  outline: 1px solid #D4B643;
}

.m-filler-selector {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  padding-left: 1px;
}
.m-filler-selector__button {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.11);
  background-color: #505050;
  border: none;
  border-radius: 3px;
  height: 96px;
  display: inline-block;
  margin-bottom: 0.5rem;
  cursor: pointer;
  position: relative;
}
.m-filler-selector__button--loading img {
  opacity: 0.3;
}
.m-filler-selector__button--loading .m-filler-selector__button__loading {
  display: flex;
}
.m-filler-selector__button__loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  align-items: center;
  justify-content: center;
}
.m-filler-selector__button__loading .a-icon {
  color: white;
  font-size: 3rem;
}
.m-filler-selector__button:hover {
  outline: 1px solid #D4B643;
}
.m-filler-selector__button--active {
  outline: 1px solid #BBBBBB;
}
.m-filler-selector__button:disabled {
  cursor: not-allowed;
  pointer-events: all;
}
.m-filler-selector__button:disabled:hover {
  outline: none;
}
.m-filler-selector__description {
  text-align: center;
  margin: 0;
}

.m-board-selection {
  min-height: 500px;
}
.m-board-selection__info {
  width: 100%;
}
.m-board-selection__tabs {
  display: flex;
}
.m-board-selection__tab {
  background-color: #333333;
  opacity: 0.6;
  padding: 0.5rem;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  cursor: pointer;
}
.m-board-selection__tab--active {
  opacity: 1;
}
.m-board-selection__inner {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.m-board-selection__detail {
  min-height: 272px;
  background-color: #333333;
  padding: 1rem;
  border-radius: 3px;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.16);
}
.m-board-selection__detail h2, .m-board-selection__detail h3, .m-board-selection__detail h4 {
  margin: 0.3333333333rem 0;
}
.m-board-selection__detail h3 {
  font-size: 18px;
}
.m-board-selection__detail h4 {
  font-size: 14px;
}
.m-board-selection__detail small {
  color: #707070;
  font-size: 12px;
}
.m-board-selection__detail .o-material__image {
  flex: 240px;
  min-width: 240px;
  max-width: 240px;
  align-self: stretch;
  height: 240px;
  margin-right: 1rem;
  margin-bottom: 0;
}
.m-board-selection__content {
  flex: 1;
}
.m-board-selection__table {
  max-height: 300px;
  overflow: auto;
  padding: 1px;
}
.m-board-selection__table thead {
  background-color: #242424;
  margin-bottom: 1px;
}

@media screen and (max-width: 800px) {
  .m-board-selection__detail {
    flex-direction: column;
  }
}
.m-configuration__header, .m-configuration__item {
  width: 100%;
  margin-bottom: 0.3333333333rem;
  background-color: #3D3D3D;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 3px;
}
.m-configuration--filler img {
  height: 44px;
  width: 44px;
}
.m-configuration__header img {
  height: 44px;
  width: 44px;
  margin-right: 0.6666666667rem;
}
.m-configuration__header h4 {
  font-size: 12px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}
.m-configuration__item {
  align-items: flex-start;
}
.m-configuration__item .a-input__radio {
  font-size: 12px;
}
.m-configuration__item p {
  font-size: 13px;
}
.m-configuration__item-info {
  background-color: #505050;
  border-radius: 3px;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.16);
  width: 100%;
  padding: 1rem;
  margin-top: 0.6666666667rem;
  font-size: 0.75rem;
}
.m-configuration__item-info span {
  margin-bottom: 0.6666666667rem;
  display: inline-block;
}
.m-configuration__item-info img {
  width: 100%;
}
.m-configuration__item-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.m-configuration__item-list li {
  display: flex;
}
.m-configuration__item-list li::before {
  content: "•";
  color: #D4B643;
  min-width: 10px;
}
.m-configuration__item-list li > span {
  min-width: 100px;
  font-size: 0.9rem;
}
.m-configuration__item-list li:not(:last-of-type) {
  margin-bottom: 0.6666666667rem;
}
.m-configuration__item-content {
  flex: 1;
}
.m-configuration__item-toggle {
  min-width: 25px;
}
.m-configuration__item-actions {
  display: flex;
  justify-content: flex-end;
}
.m-configuration__item-actions .a-button {
  margin-top: 1rem;
  width: 20px;
  height: 20px;
}
.m-configuration__item-actions .a-button.a-button--danger {
  background-color: #707070;
  color: #E3E3E3;
}
.m-configuration__item-actions .a-button.a-button--danger:hover {
  color: #E3E3E3;
  background-color: #9e3d3d;
}
.m-configuration__item-actions .a-button.a-button--success {
  background-color: #707070;
  color: #D4B643;
}
.m-configuration__item-actions .a-button.a-button--success:hover {
  color: #707070;
  background-color: #D4B643;
}
.m-configuration__item-actions .a-button .a-icon {
  font-size: 0.65rem;
}

.m-modal {
  border: 3px solid #707070;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.16);
  background-color: #242424;
  color: #BBBBBB;
  padding: 0.5rem;
  border-radius: 3px;
  max-width: 600px;
  width: 100%;
}
.m-modal--small {
  min-width: 200px;
  width: 200px;
}
.m-modal--small .m-modal__body {
  padding: 1rem;
}
.m-modal--large {
  width: 1000px;
  max-width: 1000px;
}
.m-modal--danger {
  border-color: #9e3d3d;
}
.m-modal--danger .a-icon {
  color: #9e3d3d;
}
.m-modal--warning {
  border-color: #D4B643;
}
.m-modal--warning .a-icon {
  color: #D4B643;
}
.m-modal--primary {
  border-color: #D4B643;
}
.m-modal--primary .a-icon {
  color: #D4B643;
}
.m-modal__header {
  background-color: #333333;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.16);
  color: #FFFFFF;
  border-radius: 3px;
  padding: 1rem 1.5rem;
  font-size: 1.7rem;
  display: flex;
  align-items: center;
}
.m-modal__header .a-icon {
  margin-right: 0.6666666667rem;
  font-size: 20px;
}
.m-modal__body {
  padding: 2rem;
}
.m-modal__footer {
  padding: 1rem;
  text-align: right;
}

.cdk-overlay-backdrop {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: saturate(0%);
}

.m-ribbon-item {
  height: 100%;
  width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow: hidden;
}
.m-ribbon-item form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}
.m-ribbon-item form .a-input__label {
  white-space: nowrap;
}
.m-ribbon-item--x-small {
  width: 80px;
}
.m-ribbon-item--small {
  width: 100px;
}
.m-ribbon-item__actions {
  min-width: 50px;
  display: flex;
  justify-content: flex-end;
}
.m-ribbon-item__actions .a-button {
  margin-top: 1rem;
  width: 20px;
  height: 20px;
}
.m-ribbon-item__actions .a-button.a-button--danger {
  background-color: #707070;
  color: #E3E3E3;
}
.m-ribbon-item__actions .a-button.a-button--danger:hover {
  color: #E3E3E3;
  background-color: #9e3d3d;
}
.m-ribbon-item__actions .a-button.a-button--success {
  background-color: #707070;
  color: #D4B643;
}
.m-ribbon-item__actions .a-button.a-button--success:hover {
  color: #707070;
  background-color: #D4B643;
}
.m-ribbon-item__actions .a-button .a-icon {
  font-size: 0.65rem;
}
.m-ribbon-item__button {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.11);
  background-color: #505050;
  border: none;
  border-radius: 3px;
  height: 100%;
  width: calc(100% - 2px);
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 1px;
}
.m-ribbon-item__button img {
  height: 100%;
  overflow: hidden;
}
.m-ribbon-item__button:hover {
  outline: 1px solid #D4B643;
}
.m-ribbon-item h4 {
  font-size: 10px;
  text-transform: uppercase;
  text-align: center;
  margin: 0 0 0.3333333333rem 0;
  max-width: 104px;
  padding: 0;
}
.m-ribbon-item__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  overflow: hidden;
}
.m-ribbon-item .m-ribbon-item__form {
  margin-left: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.m-ribbon-item__form--compensate-padding {
  margin-bottom: -0.3333333333rem;
}

.m-ribbon {
  height: 160px;
  display: flex;
  position: relative;
}
.m-ribbon > * {
  display: flex;
}
.m-ribbon__header {
  text-align: center;
  font-size: 10px;
  text-transform: uppercase;
}
.m-ribbon__nested {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.m-ribbon__nested-content {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
}
.m-ribbon .a-card {
  height: 100%;
}

.m-ribbon__scroll.m-ribbon__scroll--left::before {
  opacity: 0;
}
.m-ribbon__scroll.m-ribbon__scroll--right::after {
  opacity: 0;
}
.m-ribbon__scroll::before {
  transition: opacity 0.2s ease;
  pointer-events: none;
  z-index: 5;
  opacity: 1;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50px;
  background: linear-gradient(90deg, #242424 0%, rgba(36, 36, 36, 0) 100%);
}
.m-ribbon__scroll::after {
  transition: opacity 0.2s ease;
  pointer-events: none;
  z-index: 5;
  opacity: 1;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50px;
  background: linear-gradient(270deg, #242424 0%, rgba(36, 36, 36, 0) 100%);
}

.m-project-designer {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}
.m-project-designer .a-card {
  width: 100%;
}
.m-project-designer__ribbon {
  margin-bottom: 0.3333333333rem;
}
.m-project-designer__empty {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.m-project-designer__container {
  height: 100%;
}
.m-project-designer__canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.15s ease-in-out;
  opacity: 0.7;
  pointer-events: none;
  will-change: opacity;
}
.m-project-designer .active {
  opacity: 1;
  z-index: 100;
  pointer-events: auto;
}
.m-project-designer__container-card {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.m-project-designer__add {
  border-radius: 3px;
  padding: 0.3333333333rem;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.11);
  margin-bottom: 0.5rem;
  background-color: #504F4F;
}

.m-tabs {
  display: flex;
  align-items: center;
}
.m-tabs .a-icon {
  font-size: 1rem;
  margin-right: 1rem;
}
.m-tabs ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.m-tabs ul li {
  display: inline-block;
}
.m-tabs .a-button {
  border-bottom: 4px solid transparent;
  border-radius: 0;
  position: relative;
}
.m-tabs .a-button:hover, .m-tabs .a-button.a-button--active {
  border-bottom: 4px solid #D4B643;
}
.m-tabs .a-button::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0.5rem;
  width: 1px;
  height: 20px;
  background-color: #504F4F;
}
.m-tabs .a-button--small:hover, .m-tabs .a-button--small.a-button--active {
  border-bottom: 3px solid #D4B643;
}
.m-tabs .a-button--small::after {
  top: 0.5rem;
  height: 12px;
}

.m-account-switcher {
  display: flex;
  align-items: center;
}
.m-account-switcher__divider {
  display: inline-block;
  margin: 0 1rem;
}
.m-account-switcher .a-input {
  width: 200px;
  margin-bottom: 0;
}
.m-account-switcher .a-input .a-input__select.ng-select.ng-select-single {
  height: 25px;
}
.m-account-switcher .a-input .a-input__select.ng-select.ng-select-single .ng-select-container {
  height: 25px;
  min-height: 25px;
  box-shadow: none;
}
.m-account-switcher .a-input .a-input__select.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input > input {
  padding: 0.3333333333rem;
}

.m-loader {
  width: 100%;
  max-width: 100vw;
  height: 0.3333333333rem;
  position: relative;
  overflow: hidden;
}
.m-loader .m-loading-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 50;
}
.m-loader .m-loading-bar::after {
  content: "";
  position: absolute;
  top: -200px;
  left: 0;
  width: 100%;
  height: 200px;
  border-radius: 50%;
  box-shadow: 0 0 10px 0 #D4B643;
  animation-duration: 1.7s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: loadingBar;
  animation-timing-function: linear;
}

@keyframes loadingBar {
  0% {
    left: -80%;
  }
  100% {
    left: 100%;
  }
}
.m-cutout__create-button {
  border: none;
  background-color: #878787;
  font-family: "Calibri", sans-serif;
  padding: calc(0.3333333333rem + 2px) 0.3333333333rem;
  font-size: 1rem;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  margin-right: 0.5rem;
  cursor: pointer;
  margin-bottom: 1rem;
}
.m-cutout__create-button img {
  display: block;
}
.m-cutout__create-button:hover {
  color: #242424;
}
.m-cutout__create-button .a-icon {
  font-size: 1.5rem;
}
.m-cutout__create-button.m-customisation__selector--selected {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding-bottom: 2rem;
}
.m-cutout__create-button.m-customisation__selector--button .a-icon {
  font-size: 0.7rem;
}
.m-cutout__type {
  width: 12px;
}
.m-cutout__type img {
  width: 12px;
  height: auto;
}
.m-cutout__entry form {
  display: flex;
  align-items: flex-start;
}
.m-cutout__entry:not(:last-of-type) {
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #333333;
}
.m-cutout__position {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 140px;
}
.m-cutout__meta {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 90px;
}
.m-cutout__actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: auto;
}
.m-cutout__actions button:first-of-type {
  margin-right: 0.3333333333rem;
}
.m-cutout__diameter {
  width: 65px;
}
.m-cutout__field {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.m-cutout__field:not(:last-of-type) {
  margin-bottom: 0.3333333333rem;
}
.m-cutout__field .a-input__radio {
  display: flex;
  align-items: center;
  margin: 3px 0;
}
.m-cutout__field .a-input__radio span {
  margin-right: 0.3333333333rem;
}
.m-cutout__field span {
  font-size: 12px;
}
.m-cutout__field .a-button {
  background-color: #878787;
  color: #FFFFFF;
  margin-left: 0.3333333333rem;
}
.m-cutout__field .a-input {
  margin-left: 0.3333333333rem;
  margin-bottom: 0;
}
.m-cutout__colour {
  display: flex;
  flex-direction: column;
}
.m-cutout__colour__button {
  cursor: pointer;
  border-radius: 3px;
  background-color: transparent;
  border: 2px solid transparent;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.m-cutout__colour__button:not(:last-of-type) {
  margin-bottom: 6px;
}
.m-cutout__colour__button--active {
  border: 2px solid #D4B643;
}
.m-cutout__colour__button:hover {
  border: 2px solid #707070;
}
.m-cutout__colour__button--black .m-cutout__colour__inner {
  background-color: #292929;
  height: 12px;
  width: 12px;
  border-radius: 3px;
}
.m-cutout__colour__button--white .m-cutout__colour__inner {
  background-color: #FFFFFF;
  height: 12px;
  width: 12px;
  border-radius: 3px;
}

.m-drawing__page {
  position: relative;
}
.m-drawing__page canvas {
  display: block;
}
.m-drawing__drawing {
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.m-drawing__comments {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.m-drawing__comment {
  position: absolute;
  color: red;
  background-color: blue;
}

.m-panel-connection .a-input {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 0;
}
.m-panel-connection .a-input__radio {
  font-size: 12px;
}

.a-sidebar {
  flex: 0 0 15px;
  background-color: #333333;
  height: 100%;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.a-outline {
  max-width: 112px;
  margin: 1px;
  height: calc(100% - 2px);
}
.a-outline img {
  position: relative;
}
.a-outline--OTCRL img {
  top: 10px;
}
.a-outline--OTCRR img {
  top: 10px;
}
.a-outline--OTCTL img {
  right: 10px;
  bottom: 10px;
}
.a-outline--OTCTM img {
  left: 10px;
  bottom: 10px;
}
.a-outline--OTCTR img {
  left: 10px;
  bottom: 10px;
}
.a-outline--OTSL img {
  right: 10px;
  bottom: 10px;
}
.a-outline--OTSR img {
  left: 10px;
  bottom: 10px;
}
.a-outline--OSCTB img {
  right: 10px;
  top: 10px;
}
.a-outline--OSCTBT img {
  right: 10px;
}
.a-outline--OSCTBST img {
  right: 10px;
}
.a-outline--OSCTT img {
  right: 10px;
  bottom: 10px;
}
.a-outline--OSST img {
  right: 10px;
  bottom: 10px;
}
.a-outline--OFBSL img {
  right: 10px;
  bottom: 10px;
}
.a-outline--OFBSR img {
  left: 10px;
  bottom: 10px;
}

.a-card {
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  background-color: #333333;
  padding: 1.5rem;
  word-break: break-word;
  position: relative;
  width: 100%;
  z-index: 2;
}
.a-card--light {
  background-color: #3D3D3D;
}
.a-card--small {
  padding: 0.6666666667rem;
}
.a-card--x-small {
  padding: 0.5rem;
}
.a-card--scrollbar-fix {
  padding-right: 0;
}
.a-card--scrollbar-fix .a-card__content {
  padding-right: 0.6666666667rem;
}
.a-card--scrollbar-fix .ng-scrollbar {
  --scrollbar-padding: 3px ;
}
.a-card--active {
  border: 1px solid #558466;
}
.a-card--container-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
}
.a-card--container-text {
  margin-bottom: 10px;
}
.a-card > h1 {
  font-size: 1.6rem;
  font-weight: 300;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(112, 112, 112, 0.1);
}
.a-card__header {
  border-bottom: 1px solid rgba(112, 112, 112, 0.1);
  padding: 0 0 0.6666666667rem 0;
}
.a-card__header h2 {
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
}
.a-card > h3 {
  font-size: 16px;
  color: #D4B643;
  margin: 0;
  padding: 0;
}
.a-card > h4 {
  font-size: 12px;
  color: #E3E3E3;
  text-transform: uppercase;
  margin: 0 0 0.6666666667rem 0;
  padding: 0;
}

.a-input--no-margin .a-input {
  margin-bottom: 0;
}

.a-input {
  margin-bottom: 0.6666666667rem;
  position: relative;
  overflow: visible;
}
.a-input :focus {
  outline: none;
}
.a-input--configuration {
  align-items: center;
  margin-bottom: 0.3333333333rem;
}
.a-input--configuration .a-input__validation-icon {
  padding: 0 0.25rem;
}
.a-input--configuration .a-input__label {
  font-size: 13px;
}
.a-input--configuration .a-input__select.ng-select.ng-select-single .ng-clear-wrapper, .a-input--configuration .a-input__select.ng-select.ng-select-single .ng-arrow-wrapper .ng-arrow {
  color: #333333;
  border-color: #333333 transparent transparent;
}
.a-input--configuration .a-input__select.ng-select.ng-select-single .ng-select-container {
  background-color: #D4B643;
  color: #333333;
  height: 24px;
  min-height: 24px;
}
.a-input--configuration .a-input__select.ng-select.ng-select-single .ng-select-container .ng-value-container {
  padding-left: 2px;
  font-weight: bold;
}
.a-input--configuration .a-input__select.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input > input {
  padding: 2px;
}
.a-input--configuration .a-input__field {
  background-color: #D4B643;
  flex: 1;
  color: #333333;
  font-weight: 500;
  padding: 2px;
}
.a-input--configuration .a-input__field::placeholder {
  color: #333333;
}
.a-input--configuration .a-input__field:-ms-input-placeholder {
  color: #333333;
}
.a-input--configuration .a-input__field::-ms-input-placeholder {
  color: #333333;
}
.a-input--disabled {
  cursor: not-allowed;
}
.a-input--disabled .a-input__field {
  opacity: 0.65;
  cursor: not-allowed;
  background-color: #242424;
  pointer-events: none;
}
.a-input--disabled .a-input__radio, .a-input .a-input__radio--disabled {
  opacity: 0.65;
  cursor: not-allowed;
}
.a-input--transparent .a-input__field {
  background: none;
  border: none;
  padding: 0.3333333333rem;
  box-shadow: none;
}
.a-input--transparent .a-input__radio {
  opacity: 0.8;
  cursor: not-allowed;
}
.a-input .a-input__label {
  cursor: pointer;
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
}
.a-input--inline {
  display: flex;
}
.a-input--inline .a-input__field {
  text-align: center;
  max-width: 75px;
  font-size: 13px;
}
.a-input--inline .a-input__label {
  margin-right: auto;
}
.a-input--vertical {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0;
  overflow: hidden;
  flex-wrap: wrap;
}
.a-input--vertical.a-input--uses-colours .a-input__radio, .a-input--vertical.a-input--uses-badges .a-input__radio {
  margin-bottom: 0;
}
.a-input--vertical > * {
  width: auto !important;
}
.a-input--vertical img {
  margin-bottom: 0.3333333333rem;
}
.a-input--vertical .a-input__radio {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.5rem;
  overflow: hidden;
}
.a-input--vertical .a-input__radio img {
  max-width: 100%;
}
.a-input--vertical .a-input__radio span {
  margin-top: auto;
}
.a-input--vertical .a-input__checkbox {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50px;
  margin: 0.3333333333rem 0;
}
.a-input--vertical .a-input__checkbox-toggle {
  margin-right: 0;
  margin-bottom: 0.3333333333rem;
}
.a-input--vertical .a-input__checkbox span {
  font-size: 0.7rem;
  text-align: center;
}
.a-input__hidden {
  display: none;
}
.a-input__checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.a-input__checkbox--checked .a-input__checkbox-toggle {
  background-color: rgba(212, 182, 67, 0.69);
}
.a-input__checkbox--checked .a-input__checkbox-toggle::after {
  left: 13px;
  background-color: #D4B643;
}
.a-input__checkbox-toggle {
  width: 27px;
  height: 15px;
  border-radius: 8px;
  display: inline-block;
  background-color: #707070;
  position: relative;
  margin-right: 0.5rem;
}
.a-input__checkbox-toggle.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.a-input__checkbox-toggle::after {
  content: "";
  height: 13px;
  width: 13px;
  border-radius: 8px;
  background-color: #878787;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: all 0.2s ease;
}
.a-input__checkbox span {
  font-size: 0.8rem;
  text-align: center;
}
.a-input__search {
  display: flex;
  align-items: center;
  width: 300px;
}
.a-input__search .a-icon {
  margin-right: 1rem;
}
.a-input__search .a-input__field {
  box-shadow: none;
  border-bottom: 2px solid #D4B643;
  border-radius: 0;
  background: transparent;
  padding: 0.3333333333rem;
}
.a-input__field {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.11);
  background-color: #3D3D3D;
  border: none;
  border-radius: 3px;
  width: 100%;
  padding: 0.6666666667rem;
  color: #E3E3E3;
  font-size: 15px;
  font-weight: 300;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  box-shadow: inset 3px 3px 4px 0px rgba(0, 0, 0, 0.11);
  /* Firefox */
}
.a-input__field::-webkit-outer-spin-button, .a-input__field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.a-input__field[type=number] {
  -moz-appearance: textfield;
}
.a-input__field[type=range] {
  box-shadow: none;
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
  /* All the same stuff for Firefox */
}
.a-input__field[type=range]:focus {
  outline: none;
}
.a-input__field[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #FFFFFF;
  cursor: pointer;
  margin-top: -6px;
}
.a-input__field[type=range]::-moz-range-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #FFFFFF;
  cursor: pointer;
}
.a-input__field[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: #D4B643;
  border-radius: 1.5px;
}
.a-input__field[type=range]:focus::-webkit-slider-runnable-track {
  background: #D4B643;
}
.a-input__field[type=range]::-moz-range-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: #D4B643;
  border-radius: 1.5px;
}
.a-input__field::placeholder {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  color: #E3E3E3;
  font-weight: 300;
  font-size: 11px;
  opacity: 1;
}
.a-input__field:-ms-input-placeholder {
  font-weight: 300;
  font-size: 11px;
  color: #E3E3E3;
}
.a-input__field::-ms-input-placeholder {
  font-weight: 300;
  font-size: 11px;
  color: #E3E3E3;
}
.a-input__radio {
  display: flex;
  align-items: flex-start;
}
.a-input__radio:not(:last-child) {
  margin-bottom: 0.5rem;
}
.a-input__radio input {
  display: none;
}
.a-input__radio-colour {
  height: 20px;
  width: 30px;
  display: inline-block;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
}
.a-input__radio-empty {
  background-color: #505050;
  height: 20px;
  width: 30px;
  display: inline-block;
  border-radius: 3px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.a-input__radio-empty .a-icon {
  font-size: 0.7rem;
}
.a-input__radio-badge {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.11);
  background-color: #505050;
  border: none;
  border-radius: 3px;
  color: #E3E3E3;
  padding: 0 0.3333333333rem;
  text-transform: none;
  text-decoration: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  display: inline-block;
  white-space: nowrap;
}
.a-input__radio-label {
  line-height: 1em;
}
.a-input__radio--selected .a-input__radio-button::after {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #D4B643;
  position: absolute;
  top: 2px;
  left: 2px;
}
input:checked + .a-input__radio-button::after {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #D4B643;
  position: absolute;
  top: 2px;
  left: 2px;
}
input:checked + .a-input__radio-colour {
  border: 2px solid #D4B643;
}
input:checked + .a-input__radio-colour::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #3D3D3D;
}
input:checked + .a-input__radio-empty {
  border: 2px solid #D4B643;
}
input:checked + .a-input__radio-empty::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #3D3D3D;
}
input:checked + .a-input__radio-badge {
  background-color: #D4B643;
  color: #333333;
}
.a-input__radio-button {
  position: relative;
  border-radius: 50%;
  border: 1px solid #FFFFFF;
  width: 12px;
  height: 12px;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
  display: inline-block;
}
.a-input__select + app-validation-message .a-input__validation-icon {
  right: 20px;
  z-index: 10000;
}
.a-input__select.ng-select.ng-select-single.ng-select-disabled, .a-input__select.ng-select.ng-select-multiple.ng-select-disabled {
  cursor: not-allowed;
}
.a-input__select.ng-select.ng-select-single.ng-select-disabled .ng-select-container, .a-input__select.ng-select.ng-select-multiple.ng-select-disabled .ng-select-container {
  opacity: 0.8;
  background-color: #707070;
}
.a-input__select.ng-select.ng-select-single .a-icon, .a-input__select.ng-select.ng-select-multiple .a-icon {
  display: inline-block;
}
.a-input__select.ng-select.ng-select-single .ng-spinner-loader, .a-input__select.ng-select.ng-select-multiple .ng-spinner-loader {
  border-left-color: #707070;
  border-top-color: rgba(112, 112, 112, 0.3);
  border-bottom-color: rgba(112, 112, 112, 0.3);
  border-right-color: rgba(112, 112, 112, 0.3);
}
.a-input__select.ng-select.ng-select-single .ng-select-container, .a-input__select.ng-select.ng-select-multiple .ng-select-container {
  border-radius: 3px;
  background-color: #3D3D3D;
  box-shadow: inset 3px 3px 4px 0px rgba(0, 0, 0, 0.11);
  border: none;
  color: #E3E3E3;
  height: 40px;
}
.a-input__select.ng-select.ng-select-single .ng-select-container .ng-value-container, .a-input__select.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  padding-left: 0.6666666667rem;
  padding-top: 0;
  font-size: 15px;
  font-weight: 300;
  align-items: center;
  display: flex;
}
.a-input__select.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-placeholder, .a-input__select.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  color: #E3E3E3;
  font-weight: 300;
  font-size: 11px;
  opacity: 1;
  padding-bottom: 0;
  padding-left: 0;
  top: unset;
}
.a-input__select.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input, .a-input__select.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding: 0;
  top: 0;
}
.a-input__select.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input > input, .a-input__select.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input > input {
  color: #E3E3E3;
  font-size: 15px;
  font-weight: 300;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  width: 100%;
  padding: 0.6666666667rem;
}
.a-input__select.ng-select.ng-select-single .ng-value .a-icon, .a-input__select.ng-select.ng-select-single .ng-option .a-icon, .a-input__select.ng-select.ng-select-multiple .ng-value .a-icon, .a-input__select.ng-select.ng-select-multiple .ng-option .a-icon {
  position: relative;
  top: 2px;
  margin-right: 0.3333333333rem;
}
.a-input__select.ng-select.ng-select-single .ng-clear-wrapper, .a-input__select.ng-select.ng-select-multiple .ng-clear-wrapper {
  color: #E3E3E3;
}
.a-input__select.ng-select.ng-select-single .ng-arrow-wrapper .ng-arrow, .a-input__select.ng-select.ng-select-multiple .ng-arrow-wrapper .ng-arrow {
  border-color: #E3E3E3 transparent transparent;
}
.a-input__select.ng-select.ng-select-single .ng-dropdown-panel, .a-input__select.ng-select.ng-select-multiple .ng-dropdown-panel {
  padding-top: 0.6666666667rem;
  border-radius: 3px;
  background-color: #707070;
  border: none;
  z-index: 50;
  left: 0.3333333333rem;
  width: calc(100% - 0.6666666667rem);
}
.a-input__select.ng-select.ng-select-single .ng-dropdown-panel.ng-select-bottom, .a-input__select.ng-select.ng-select-multiple .ng-dropdown-panel.ng-select-bottom {
  top: calc(100% - 0.5rem);
}
.a-input__select.ng-select.ng-select-single .ng-dropdown-panel .ng-dropdown-panel-items, .a-input__select.ng-select.ng-select-multiple .ng-dropdown-panel .ng-dropdown-panel-items {
  border-radius: 3px;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.11);
  padding: 0.6666666667rem;
}
.a-input__select.ng-select.ng-select-single .ng-dropdown-panel .ng-dropdown-panel-items .ng-option, .a-input__select.ng-select.ng-select-multiple .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #707070;
  color: #E3E3E3;
  border-radius: 3px;
}
.a-input__select.ng-select.ng-select-single .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover, .a-input__select.ng-select.ng-select-multiple .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
  outline: 1px solid #FFFFFF;
}
.a-input__validation-icon {
  cursor: pointer;
  padding: 0.6666666667rem;
  position: absolute;
  z-index: 5;
  bottom: 0;
  right: 0;
}
.a-input__validation-messages {
  font-size: 0.8rem;
}
.a-input__validation-messages ul {
  margin: 0;
  padding-left: 1rem;
}
.a-input__validation div.ngxp__container {
  min-width: 300px;
  background-color: #ff6565;
}
.a-input__validation div.ngxp__container > .ngxp__arrow:before {
  background-color: #ff6565;
}

.a-button {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.11);
  background-color: #505050;
  border: none;
  border-radius: 3px;
  color: #E3E3E3;
  padding: 0.6666666667rem 1rem;
  text-transform: none;
  text-decoration: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  display: inline-block;
  white-space: nowrap;
}
.a-button app-icon {
  display: inline-block;
}
.a-button:hover {
  background-color: rgba(212, 182, 67, 0.45);
  color: #E3E3E3;
}
.a-button.a-button--block {
  width: 100%;
  display: block;
}
.a-button.a-button--small {
  padding: 0.3333333333rem 0.5rem;
}
.a-button.a-button--small .a-icon {
  padding: 0.1666666667rem 0;
}
.a-button.a-button--x-small {
  padding: 0 0.3333333333rem;
}
.a-button.a-button--x-small .a-icon {
  padding: 0.3333333333rem 0;
}
.a-button.a-button--2x-small {
  padding: 0 0.3333333333rem;
}
.a-button.a-button--2x-small .a-icon {
  font-size: 0.8rem;
  padding: 2px 0;
}
.a-button.a-button--no-padding {
  padding: 0;
}
.a-button.a-button--danger {
  background-color: #9e3d3d;
}
.a-button.a-button--danger:hover {
  color: #D4B643;
}
.a-button.a-button--secondary {
  background-color: #707070;
  color: #000000;
  font-weight: normal;
  font-family: "Calibri", sans-serif;
  font-weight: 300;
  padding: 3px 0.6666666667rem 0;
}
.a-button.a-button--secondary:hover, .a-button.a-button--secondary.a-button--active {
  background-color: #D4B643;
}
.a-button.a-button--success {
  background-color: #3A754E;
}
.a-button.a-button--success:hover {
  color: #D4B643;
}
.a-button.a-button--transparent {
  background: none;
  box-shadow: none;
  border: none;
}
.a-button.a-button--transparent:hover, .a-button.a-button--transparent:hover .a-icon {
  color: #D4B643;
}
.a-button.a-button--transparent.a-button--danger:hover .a-icon {
  color: #9e3d3d;
}
.a-button.a-button--light {
  background-color: #707070;
  color: #242424;
}
.a-button.a-button--light:hover, .a-button.a-button--light:hover .a-icon {
  background-color: #D4B643;
}
.a-button.a-button--primary {
  color: #D4B643;
}
.a-button.a-button--primary:hover, .a-button.a-button--primary:hover .a-icon {
  background-color: #3A754E;
  color: #D4B643;
}
.a-button.a-button--active:not(.a-button--transparent) {
  background-color: #D4B643;
  color: #242424;
}
.a-button.a-button--active:not(.a-button--transparent):hover, .a-button.a-button--active:not(.a-button--transparent):hover .a-icon {
  color: #D4B643;
  background-color: #505050;
}
.a-button__icon {
  height: 15px;
  margin-left: 0.5rem;
  width: auto;
}
.a-button__icon--spin {
  animation: spin 3s linear 0s infinite;
}
.a-button--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  filter: grayscale(1);
}

button:disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.6;
}

.a-tag {
  font-size: 9px;
  color: #000000;
  text-transform: uppercase;
  border-radius: 1rem;
  border: none;
  padding: 0.2rem 0.3333333333rem;
  display: inline-block;
  background-color: #FFFFFF;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.16);
  font-weight: 500;
}
.a-tag--clickable {
  cursor: pointer;
}

.a-icon {
  display: block;
}
.a-icon--spin {
  animation: spin 3s linear 0s infinite;
}
.a-icon--muted {
  color: #BBBBBB;
}

.kastenman-icon-configurator {
  transform: scale(1.25);
}

.a-link {
  text-transform: uppercase;
  color: #707070;
  font-weight: 300;
  font-size: 11px;
}
.a-link:hover {
  color: #D4B643;
  text-decoration-color: #D4B643;
}
.a-link--lowercase {
  text-transform: lowercase;
}

.a-boards {
  background-color: #504F4F;
  padding: 0.3333333333rem;
  border-radius: 3px;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.11);
  margin-bottom: 0.5rem;
}
.a-boards--overview .a-boards__item {
  display: flex;
  align-items: flex-start;
}
.a-boards--overview .a-boards__item:not(:last-of-type) {
  padding-bottom: 0.3333333333rem;
  margin-bottom: 0.3333333333rem;
  border-bottom: 1px solid #707070;
}
.a-boards--item {
  min-height: 117px;
}
.a-boards__materials {
  margin-right: 0.3333333333rem;
  max-width: 150px;
}
.a-boards__materials button:not(:last-of-type) {
  margin-bottom: 0.3333333333rem;
}
.a-boards--loading {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #504F4F 8%, rgba(112, 112, 112, 0.6) 18%, #504F4F 33%);
  background-size: 800px 104px;
}
.a-boards img {
  width: 100%;
  border-radius: 3px;
  height: 50px;
  object-fit: cover;
  display: inline-block;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #504F4F;
  margin-bottom: 0.5rem;
}
.a-boards__info p {
  margin: 0;
}
.a-boards__item {
  font-size: 12px;
  display: flex;
  align-items: center;
}
.a-boards__item:not(:last-child) {
  margin-bottom: 0.25rem;
}
.a-boards__item button {
  min-height: 25px;
  padding: 0.3333333333rem;
  border-radius: 3px;
  background-color: #3D3D3D;
  display: inline-block;
  color: #E3E3E3;
  width: 100%;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.11);
  border: none;
  text-align: left;
  font-size: 12px;
  font-weight: 300;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.a-boards__item button:hover {
  outline: 1px solid #707070;
}
.a-boards__item--full-width button {
  width: 100%;
  min-width: none;
  max-width: none;
}
@media screen and (max-width: 1100px) {
  .a-boards__item--full-width button {
    max-width: 280px;
  }
}

.a-canvas {
  height: 100%;
  width: 100%;
  position: relative;
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}
.a-canvas__canvas {
  height: 100%;
  width: 100%;
  border-radius: 3px;
  overflow: hidden;
}
.a-canvas__temp {
  background-color: #E3E3E3;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  border-radius: 3px;
  overflow: hidden;
}
.a-canvas__fullscreen {
  position: absolute;
  top: 0.3333333333rem;
  right: 0.3333333333rem;
}
.a-canvas__fullscreen .a-button {
  color: #242424;
}
.a-canvas__fullscreen .a-button .a-icon {
  font-size: 1.5rem;
}
.a-canvas__isolation {
  position: absolute;
  top: 0.3333333333rem;
  left: 0.3333333333rem;
}
.a-canvas__isolation .a-button {
  color: #242424;
}
.a-canvas__isolation .a-button .a-icon {
  font-size: 1.5rem;
}
.a-canvas__hint {
  position: absolute;
  top: 0.3333333333rem;
  left: 0.3333333333rem;
  text-align: left;
  background-color: #504F4F;
  padding: 0.3333333333rem 0.6666666667rem;
  border-radius: 3px;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.16);
}
.a-canvas__measurements {
  position: absolute;
  bottom: 0.3333333333rem;
  right: 0.3333333333rem;
  text-align: left;
  background-color: #504F4F;
  padding: 0.3333333333rem 0.6666666667rem;
  border-radius: 3px;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.16);
}
.a-canvas__measurements h3 {
  margin: 0.3333333333rem 0 0 0;
  padding: 0;
  font-size: 16px;
  color: #FFFFFF;
}
.a-canvas__measurements p {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #BBBBBB;
}
.a-canvas__buttons {
  position: absolute;
  top: 6rem;
  right: 0.3333333333rem;
  display: flex;
  flex-direction: column;
  background-color: #333333;
  padding: 0.3333333333rem;
  border-radius: 3px;
}
.a-canvas__buttons img {
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.16);
}
.a-canvas__buttons .a-input__label {
  width: 20px;
}
.a-canvas__button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  margin: 0.2rem 0.1rem;
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.75;
}
.a-canvas__button--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  filter: grayscale(1);
}
.a-canvas__button:hover {
  opacity: 1;
}
.a-canvas__button--active {
  opacity: 1;
  outline: 1px solid #D4B643;
  border-radius: 3px;
}
.a-canvas__button img {
  height: 44px;
  width: 44px;
  display: block;
}
.a-canvas__button-divider {
  width: 100%;
  height: 6px;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.16);
  background-color: #3D3D3D;
  margin: 0.5rem 0;
}

.a-tooltip {
  text-align: center;
  z-index: 100;
  position: fixed;
  padding: 6px 12px;
  font-size: 0.66rem;
  font-weight: 600;
  line-height: initial;
  color: white;
  width: auto;
  background: rgba(17, 17, 17, 0.9333333333);
  box-sizing: border-box;
  opacity: 1;
  transform: translate(-50%, 0%);
  animation-fill-mode: forwards;
  pointer-events: none;
}

.a-scrollbar {
  --scrollbar-thumb-color: rgba(255, 255, 255, 0.4) !important;
  --scrollbar-thumb-hover-color: rgba(255, 255, 255, 0.6) !important;
}

html,
body {
  height: 100%;
}

.u-root {
  display: flex;
  flex: 1;
  min-height: 100vh;
  width: 100%;
}
.u-root ng-component {
  flex: 1;
  display: flex;
}

.ng-scroll-content {
  padding: 1px;
}

.overlay-container .toast-container .ngx-toastr {
  width: 500px;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

body {
  color: #E3E3E3;
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-weight: 300;
  background-color: #242424;
}

a.a-mail {
  text-transform: none;
  text-decoration: underline;
  font-weight: 300;
  color: #E3E3E3;
}
a.a-mail:hover {
  color: #D4B643;
  text-decoration: none;
}

a {
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 300;
  color: #E3E3E3;
}
a:hover {
  color: #D4B643;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.mat-mdc-unelevated-button.mat-primary,
.mat-mdc-raised-button.mat-primary,
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  text-transform: uppercase;
  font-size: 12px;
  background-color: var(--background);
}
.mat-mdc-unelevated-button.mat-primary:hover,
.mat-mdc-raised-button.mat-primary:hover,
.mat-mdc-fab.mat-primary:hover,
.mat-mdc-mini-fab.mat-primary:hover {
  background-color: var(--yellow);
}

.mat-drawer-container {
  background-color: #fff;
}

.mat-mdc-card.matcard-stripes {
  padding: 40px 10px 40px;
}
.mat-mdc-card.matcard-stripes:after, .mat-mdc-card.matcard-stripes:before {
  content: "";
  position: absolute;
  bottom: 10%;
  display: block;
  height: 1px;
}
.mat-mdc-card.matcard-stripes:after {
  right: 0;
  width: 70%;
  background-color: var(--yellow);
}
.mat-mdc-card.matcard-stripes:before {
  left: 0;
  width: 30%;
  background-color: var(--background);
}

h3.card-title {
  margin-bottom: 25px;
  font-weight: 300;
  font-size: 20px;
  line-height: 22px;
  color: #595959;
}

p.card-text {
  margin-bottom: 50px;
  font-weight: 300;
  font-size: 17px;
  line-height: 30px;
}

.btn.align-left {
  padding-left: 0;
}

.mat-mdc-card.form-container {
  min-width: 300px;
  margin: 10% auto;
  padding: 65px 35px;
}

p.error-message {
  display: block;
  margin-bottom: 45px;
  padding: 10px 25px;
  border: 1px solid #f44336;
  border-radius: 5px;
  color: #f44336;
  text-align: center;
}

.loading-spinner {
  margin: 20px auto;
}

.sidenav {
  height: fit-content;
}

.m-xxs {
  margin: 2px 4px;
}

.m {
  margin: 15px;
}

.m-xs {
  margin: 5px;
}

.m-sm {
  margin: 10px;
}

.m {
  margin: 15px;
}

.m-md {
  margin: 20px;
}

.m-lg {
  margin: 30px;
}

.m-xl {
  margin: 50px;
}

.m-n {
  margin: 0 !important;
}

.m-l-none {
  margin-left: 0;
}

.m-l-xs {
  margin-left: 5px;
}

.m-l-sm {
  margin-left: 10px;
}

.m-l {
  margin-left: 15px;
}

.m-l-md {
  margin-left: 20px;
}

.m-l-lg {
  margin-left: 30px;
}

.m-l-xl {
  margin-left: 40px;
}

.m-t-none {
  margin-top: 0 !important;
}

.m-t-xxs {
  margin-top: 1px;
}

.m-t-xs {
  margin-top: 5px;
}

.m-t-sm {
  margin-top: 10px;
}

.m-t {
  margin-top: 15px;
}

.m-t-md {
  margin-top: 20px;
}

.m-t-lg {
  margin-top: 30px;
}

.m-t-xl {
  margin-top: 50px;
}

.m-r-none {
  margin-right: 0;
}

.m-r-xxs {
  margin-right: 1px;
}

.m-r-xs {
  margin-right: 5px;
}

.m-r-sm {
  margin-right: 10px;
}

.m-r {
  margin-right: 15px;
}

.m-r-md {
  margin-right: 20px;
}

.m-r-lg {
  margin-right: 30px;
}

.m-r-xl {
  margin-right: 40px;
}

.m-b-none {
  margin-bottom: 0;
}

.m-b-xxs {
  margin-bottom: 1px;
}

.m-b-xs {
  margin-bottom: 5px;
}

.m-b-sm {
  margin-bottom: 10px;
}

.m-b {
  margin-bottom: 15px;
}

.m-b-md {
  margin-bottom: 20px;
}

.m-b-lg {
  margin-bottom: 30px;
}

.m-b-xl {
  margin-bottom: 40px;
}

.search {
  margin: 50px 30px 30px 0;
}
.search mat-form-field {
  width: 400px;
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
}
.search mat-form-field .mat-form-field-flex {
  border-radius: 5px;
  padding: 3px;
}
.search mat-form-field .mat-form-field-underline {
  display: none;
}
.search mat-icon {
  position: relative;
  top: 6px;
  font-size: 25px !important;
  left: 10px;
  width: 40px;
  color: var(--gray);
}

.table-container {
  margin: 20px;
}
.table-container .table__header-row {
  background-color: #f2f2f2;
  height: 32px;
}
.table-container .table__header-row th {
  font-size: 13px;
  color: var(--black);
}
.table-container .table__items-row {
  height: 32px;
}
.table-container .table__items-row td {
  padding-right: 80px;
}
.table-container .table__items-row td.center {
  text-align: center;
}
.table-container .table__items-row td.action-icon {
  padding-right: 0px;
}
.table-container .table__items-row td mat-icon:hover {
  cursor: pointer;
}
.table-container .table__items-row:hover {
  background-color: #f2f2f2;
}

.a-clickable {
  cursor: pointer;
}

.main-menu__item {
  box-sizing: border-box;
  padding: 12px 10px 12px 5px !important;
  font-size: 15px !important;
  font-weight: 300;
  color: var(--gray) !important;
  background-color: #fafafa;
  border-bottom: 1px solid #e1e1e1;
}
.main-menu__item:hover {
  color: var(--black);
  background-color: var(--background);
}
.main-menu__item.active {
  color: #e8c500 !important;
  background-color: rgba(255, 255, 0, 0.2) !important;
}
.main-menu__icon {
  margin-right: 10px;
  font-size: 20px;
}
.main-menu .title {
  text-transform: uppercase;
  pointer-events: none;
  cursor: default;
}

.mat-mdc-list-base {
  padding-top: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
.mat-list-item-content {
  height: auto;
}

.mat-mdc-list-base .mat-mdc-list-item,
.mat-list-base .mat-list-option {
  height: fit-content !important;
}

.client-form-dialog .mat-mdc-dialog-container {
  border-top: 25px solid white;
  border-bottom: 25px solid white;
}

mat-sidenav-container {
  height: calc(100vh - 50px);
}

.toolbar {
  display: block;
  background-color: #fff;
  border-bottom: 1px solid var(--background);
}
.toolbar__title {
  display: inline-block;
  margin-right: 50px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 300;
}

.form__section {
  margin-bottom: 50px;
}

p.form__title {
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 300;
}

p.form__subtitle {
  margin-bottom: 30px;
  font-size: 22px;
  font-weight: 500;
}

.mat-mdc-simple-snack-bar {
  display: inline-block !important;
}

.mat-mdc-snack-bar-container {
  text-align: center;
  color: white;
}
.mat-mdc-snack-bar-container.success {
  background-color: #51a351;
}
.mat-mdc-snack-bar-container.error {
  background-color: #cf261d;
}
.mat-mdc-snack-bar-container.info {
  background-color: #3276b1;
}
.mat-mdc-snack-bar-container.warning {
  background-color: #f89406;
}

.shepherd-content {
  background-color: #D4B643;
}

.shepherd-text {
  font-family: "Roboto", "Helvetica Neue", sans-serif !important;
  padding: 0.75rem 0.5rem !important;
}

.shepherd-arrow::before {
  background: #D4B643 !important;
}

.shepherd-info {
  width: auto !important;
}