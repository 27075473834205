.a-tag {
	font-size: 9px;
	color: $black;
	text-transform: uppercase;
	border-radius: $spacer;
	border: none;
	padding: 0.2rem $spacer-2x-small;
	display: inline-block;
	background-color: $white;
	box-shadow: $box-shadow;
	font-weight: 500;

	&--clickable {
		cursor: pointer;
	}
}
