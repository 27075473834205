.a-link {
	text-transform: uppercase;
	color: $gray;
	font-weight: 300;
	font-size: 11px;

    &:hover {
		color: $primary;
		text-decoration-color: $primary;
    }

	&--lowercase {
		text-transform: lowercase;
	}
}
