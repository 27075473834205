.o-configurator {
	display: flex;
	flex: 1;
	padding: $spacer-2x-small;
	padding-top: 0;
	width: calc(100vw - 26px);

	&__container {
		display: flex;
		flex: 1;
		padding-top: 0;
		height: calc(100vh - 60px);
	}


	&__navigation {
		min-width: 360px;
		width: 360px;
		padding-right: $spacer-2x-small;
	}

	&__designer {
		display: flex;
		flex: 1;
		min-width: 360px;
		width: 100%;
		overflow: hidden;

		> * {
			overflow: hidden;
			display: flex;
			flex: 1;
		}
	}

	&__configuration {
		min-width: 430px;
		width: 430px;
		padding-left: $spacer-2x-small;
		display: flex;
		height: calc(100vh - 60px);

		@media screen and (max-width: 1100px) {
			min-width: 360px;
			width: 360px;
		}

		> * {
			width: 100%;

			> * > * {
				overflow-y: auto;
				height: 100%;
				max-height: calc(100vh - 60px);

				&::-webkit-scrollbar-thumb {
					display: none;
				}

				&::-webkit-scrollbar {
					display: none;
				}
			}
		}

	}

	&__wrapper {
		display: flex;
		flex-wrap: wrap;
	}

	> ng-component {
		display: flex;
		flex: 1;
		overflow: hidden;
	}
}
