.m-card-tabs {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	align-items: flex-end;

	li {
		display: inline-block;
	}

	&__item {
		background-color: $dark-gray;
		border: none;
		font-size: 14px;
		font-weight: 300;
		font-family: 'Roboto', sans-serif;
		color: $light-gray;
		padding: $spacer-2x-small $spacer-x-small;
		border-top-left-radius: $border-radius;
		border-top-right-radius: $border-radius;
		box-shadow: $box-shadow-light;
		cursor: pointer;

		&:hover {
			color: $primary;
		}

		&--active {
			position: relative;
			font-size: 16px;
			color: $primary;
			padding: $spacer-x-small;
			box-shadow: $box-shadow;
			z-index: 1;
		}
	}
}
