.a-input--no-margin {
	.a-input {
		margin-bottom: 0;
	}
}

.a-input {
	margin-bottom: $spacer-small;
	position: relative;
	overflow: visible;

	:focus {
		outline: none;
	}

	&--configuration {
		align-items: center;
		margin-bottom: $spacer-2x-small;

		.a-input__validation-icon {
			padding: 0 $spacer-3x-small;
		}

		.a-input__label {
			font-size: 13px;
		}

		.a-input__select.ng-select.ng-select-single {
			.ng-clear-wrapper, .ng-arrow-wrapper .ng-arrow {
				color: $dark-gray;
				border-color: $dark-gray transparent transparent;
			}

			.ng-select-container {
				background-color: $primary;
				color: $dark-gray;
				height: 24px;
				min-height: 24px;

				.ng-value-container {
					padding-left: 2px;
					font-weight: bold;

					.ng-input > input {
						padding: 2px;
					}
				}
			}
		}

		.a-input__field {
			background-color: $primary;
			flex: 1;
			color: $dark-gray;
			font-weight: 500;
			padding: 2px;

			&::placeholder {
				color: $dark-gray;
			}

			&:-ms-input-placeholder {
				color: $dark-gray;
			}

			&::-ms-input-placeholder {
				color: $dark-gray;
			}
		}
	}

	&--disabled {
		cursor: not-allowed;

		.a-input__field {
			opacity: 0.65;
			cursor: not-allowed;
			background-color: $darker-gray;
			pointer-events: none;
		}
	}

	&--disabled .a-input__radio, .a-input__radio--disabled {
		opacity: 0.65;
		cursor: not-allowed;
	}

	&--transparent {
		.a-input__field {
			background: none;
			border: none;
			padding: $spacer-2x-small;
			box-shadow: none;
		}

		.a-input__radio {
			opacity: 0.8;
			cursor: not-allowed;
		}
	}

	.a-input__label {
		cursor: pointer;
		font-size: .9rem;
		margin-bottom: 0.25rem;
	}

	&--inline {
		display: flex;

		.a-input__field {
			text-align: center;
			max-width: 75px;
			font-size: 13px;
		}

		.a-input__label {
			margin-right: auto;
		}
	}

	&--vertical {
		display: flex;
		gap: $spacer-x-small;
		// justify-content: space-around;
		margin-bottom: 0;
		overflow: hidden;
		flex-wrap: wrap;

		&.a-input--uses-colours, &.a-input--uses-badges {
			.a-input__radio {
				margin-bottom: 0;
			}
		}

		> * {
			width: auto !important;
		}

		img {
			margin-bottom: $spacer-2x-small;
		}

		.a-input__radio {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: $spacer-x-small;
			overflow: hidden;

			img {
				// width: 50px;
				max-width: 100%;
			}

			span {
				margin-top: auto;
			}
		}

		.a-input__checkbox {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 50px;
			margin: $spacer-2x-small 0;

			&-toggle {
				margin-right: 0;
				margin-bottom: $spacer-2x-small;
			}

			span {
				font-size: 0.7rem;
				text-align: center;
			}
		}
	}

	&__hidden {
		display: none;
	}

	&__checkbox {
		cursor: pointer;
		display: flex;
		align-items: center;

		&--checked &-toggle {
			background-color: rgba($primary, 0.69);

			&::after {
				left: 13px;
				background-color: $primary;
			}
		}

		&-toggle {
			width: 27px;
			height: 15px;
			border-radius: 8px;
			display: inline-block;
			background-color: $gray;
			position: relative;
			margin-right: $spacer-x-small;

			&.disabled {
				opacity: 0.5;
				cursor: not-allowed;
			}

			&::after {
				content: "";
				height: 13px;
				width: 13px;
				border-radius: 8px;
				background-color: $search-gray;
				position: absolute;
				top: 1px;
				left: 1px;
				transition: all .2s ease;
			}
		}

		span {
			font-size: 0.8rem;
			text-align: center;
		}
	}

	&__search {
		display: flex;
		align-items: center;
		width: 300px;

		.a-icon {
			margin-right: $spacer;
		}

		.a-input__field {
			box-shadow: none;
			border-bottom: 2px solid $primary;
			border-radius: 0;
			background: transparent;
			padding: $spacer-2x-small;
		}
	}

	&__field {
		box-shadow: $box-shadow-light;
		background-color: $input-background;
		border: none;
		border-radius: $border-radius;
		width: 100%;
		padding: $spacer-small;
		color: $lighter-gray;
		font-size: 15px;
		font-weight: 300;
		font-family: 'Roboto', "Helvetica Neue", sans-serif;
		box-shadow: $box-shadow-inset;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* Firefox */
		&[type=number] {
		 	-moz-appearance: textfield;
		}

		&[type=range] {
			box-shadow: none;
			-webkit-appearance: none; /* Hides the slider so that custom slider can be made */
			width: 100%; /* Specific width is required for Firefox. */
			background: transparent; /* Otherwise white in Chrome */

			&:focus {
				outline: none;
			}

			&::-webkit-slider-thumb {
				-webkit-appearance: none;
				height: 15px;
				width: 15px;
				border-radius: 50%;
				background: $white;
				cursor: pointer;
				margin-top: -6px;
			}

			/* All the same stuff for Firefox */
			&::-moz-range-thumb {
				height: 15px;
				width: 15px;
				border-radius: 50%;
				background: $white;
				cursor: pointer;
			}

			&::-webkit-slider-runnable-track {
				width: 100%;
				height: 3px;
				cursor: pointer;
				background: $primary;
				border-radius: 1.5px;
			}

			&:focus::-webkit-slider-runnable-track {
				background: $primary;
			}

			&::-moz-range-track {
				width: 100%;
				height: 3px;
				cursor: pointer;
				background: $primary;
				border-radius: 1.5px;
			}
		}

		&::placeholder {
			font-family: 'Roboto', "Helvetica Neue", sans-serif;
			color: $lighter-gray;
			font-weight: 300;
			font-size: 11px;
			opacity: 1;
		}

		&:-ms-input-placeholder {
			font-weight: 300;
			font-size: 11px;
			color: $lighter-gray;
		}

		&::-ms-input-placeholder {
			font-weight: 300;
			font-size: 11px;
			color: $lighter-gray;
		}
	}

	&__radio {
		display: flex;
		align-items: flex-start;

		&:not(:last-child) {
			margin-bottom: $spacer-x-small;
		}

		input {
			display: none;
		}

		&-colour {
			height: 20px;
			width: 30px;
			display: inline-block;
			border-radius: $border-radius;
			position: relative;
			cursor: pointer;
		}

		&-empty {
			background-color: #505050;
			height: 20px;
			width: 30px;
			display: inline-block;
			border-radius: $border-radius;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			.a-icon {
				font-size: 0.7rem;
			}
		}

		&-badge {
			box-shadow: $box-shadow-light;
			background-color: #505050;
			border: none;
			border-radius: $border-radius;
			color: $lighter-gray;
			padding: 0 $spacer-2x-small;
			text-transform: none;
			text-decoration: none;
			cursor: pointer;
			font-size: 15px;
			font-weight: 300;
			font-family: 'Roboto', sans-serif;
			display: inline-block;
			white-space: nowrap;
		}

		&-label {
			line-height: 1em;
		}

		&--selected {
			.a-input__radio-button::after {
				content: '';
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background-color: $primary;
				position: absolute;
				top: 2px;
				left: 2px;
			}
		}

		// img {
		// 	box-shadow: $box-shadow;
		// }

		input:checked + &-button::after {
			content: '';
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background-color: $primary;
			position: absolute;
			top: 2px;
			left: 2px;
		}

		input:checked + &-colour {
			border: 2px solid $primary;

			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				border: 1px solid $input-background;
			}
		}

		input:checked + &-empty {
			border: 2px solid $primary;

			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				border: 1px solid $input-background;
			}
		}

		input:checked + &-badge {
			background-color: $primary;
			color: $dark-gray;
		}

		&-button {
			position: relative;
			border-radius: 50%;
			border: 1px solid $white;
			width: 12px;
			height: 12px;
			margin-right: $spacer-3x-small;
			margin-bottom: $spacer-3x-small;
			display: inline-block;
		}
	}

	&__select + app-validation-message &__validation {
		&-icon {
			right: 20px;
			z-index: 10000;
		}
	}

	&__select.ng-select.ng-select-single, &__select.ng-select.ng-select-multiple {
		&.ng-select-disabled {
			cursor: not-allowed;

			.ng-select-container {
				opacity: 0.8;
				background-color: $gray;
			}
		}

		.a-icon {
			display: inline-block;
		}

		.ng-spinner-loader {
			border-left-color: $gray;
			border-top-color: rgba($gray, 0.3);
			border-bottom-color: rgba($gray, 0.3);
			border-right-color: rgba($gray, 0.3);
		}

		.ng-select-container {
			border-radius: $border-radius;
			background-color: $input-background;
			box-shadow: $box-shadow-inset;
			border: none;
			color: $lighter-gray;
			height: 40px;

			.ng-value-container {
				font-family: 'Roboto', "Helvetica Neue", sans-serif;
				padding-left: $spacer-small;
				padding-top: 0;
				font-size: 15px;
				font-weight: 300;
				align-items: center;
				display: flex;

				.ng-placeholder {
					font-family: 'Roboto', "Helvetica Neue", sans-serif;
					color: $lighter-gray;
					font-weight: 300;
					font-size: 11px;
					opacity: 1;
					padding-bottom: 0;
					padding-left: 0;
					top: unset;
				}

				.ng-input {
					padding: 0;
					top: 0;

					> input {
						color: $lighter-gray;
						font-size: 15px;
						font-weight: 300;
						font-family: 'Roboto', "Helvetica Neue", sans-serif;
						width: 100%;
						padding: $spacer-small;
						// padding: 0;
					}
				}
			}
		}

		.ng-value .a-icon, .ng-option .a-icon {
			position: relative;
			top: 2px;
			margin-right: $spacer-2x-small;
		}

		.ng-clear-wrapper {
			color: $lighter-gray;
		}

		.ng-arrow-wrapper .ng-arrow {
			border-color: $lighter-gray transparent transparent;
		}

		.ng-dropdown-panel {
			padding-top: $spacer-small;
			border-radius: $border-radius;
			background-color: $gray;
			border: none;
			z-index: 50;
			left: $spacer-2x-small;
			width: calc(100% - #{$spacer-small});

			&.ng-select-bottom {
				top: calc(100% - #{$spacer-x-small});
			}

			.ng-dropdown-panel-items {
				border-radius: $border-radius;
				box-shadow: $box-shadow-light;
				padding: $spacer-small;

				.ng-option {
					background-color: $gray;
					color: $lighter-gray;
					border-radius: $border-radius;

					&:hover {
						outline: 1px solid $white;
					}
				}
			}
		}
	}

	&__validation {
		&-icon {
			cursor: pointer;
			padding: $spacer-small;
			position: absolute;
			z-index: 5;
			bottom: 0;
			right: 0;
		}

		&-messages {
			font-size: 0.8rem;

			ul {
				margin: 0;
				padding-left: $spacer;
			}
		}

		div.ngxp__container {
			min-width: 300px;
			background-color: $danger-light;

			> .ngxp__arrow:before {
				background-color: $danger-light;
			}
		}
	}
}
