//FLEX
.u-flex {
	display: flex;
	align-items: flex-start;

	&.u-flex--center {
		justify-content: center;
		align-items: center;
	}

	&.u-flex--space-between {
		justify-content: space-between;
	}

	&.u-flex--end {
		justify-content: flex-end;
	}

	.u-flex--1 {
		flex: 1;
	}
}

.u-hidden {
 display: none;
}

.u-full-width {
	width: 100%;
}

.u-margin-top-2x-large {
    margin-top: $spacer-2x-large;
}

.u-margin-top-x-large {
    margin-top: $spacer-x-large;
}

.u-margin-top-lg {
    margin-top: $spacer-large;
}

.u-margin-top-none {
    margin-top: 0;
}

.u-margin-top {
    margin-top: $spacer;
}

.u-margin-top-sm {
    margin-top: $spacer-small;
}

.u-margin-top-x-small {
    margin-top: $spacer-x-small;
}

.u-margin-top-2x-small {
    margin-top: $spacer-2x-small;
}

.u-margin-bottom-lg {
    margin-bottom: $spacer-large;
}

.u-margin-bottom-none {
    margin-bottom: 0;
}

.u-margin-bottom-x-large {
    margin-bottom: $spacer-x-large;
}

.u-margin-bottom {
    margin-bottom: $spacer;
}

.u-margin-bottom-sm {
    margin-bottom: $spacer-small;
}

.u-margin-bottom-x-small {
    margin-bottom: $spacer-x-small;
}

.u-margin-bottom-2x-small {
    margin-bottom: $spacer-2x-small;
}

.u-margin-left-lg {
    margin-left: $spacer-large;
}

.u-margin-left-none {
    margin-left: 0;
}

.u-margin-left {
    margin-left: $spacer;
}

.u-margin-left-auto {
    margin-left: auto;
}

.u-margin-left-sm {
    margin-left: $spacer-small;
}

.u-margin-left-x-small {
    margin-left: $spacer-x-small;
}

.u-margin-left-2x-small {
    margin-left: $spacer-2x-small;
}

.u-margin-right-lg {
    margin-right: $spacer-large;
}

.u-margin-right {
    margin-right: $spacer;
}

.u-margin-right-auto {
    margin-right: auto;
}

.u-margin-right-sm {
    margin-right: $spacer-small;
}

.u-margin-right-x-small {
    margin-right: $spacer-x-small;
}

.u-margin-right-2x-small {
    margin-right: $spacer-2x-small;
}

.u-margin-right-none {
    margin-right: 0;
}
