.o-material {
	background-color: $dark-gray;
	flex: 1;
	display: flex;
	flex-direction: column;
	height: 100%;

	&__type {
		margin-top: $spacer;
		box-shadow: $box-shadow;
	}

	&__preview-image {
		height: 15px;
		width: 25px;
		border-radius: $border-radius;
	}

	&__image {
		height: 300px;
		margin-bottom: $spacer;
		background-size: cover;
	}

	&__content {
		display: flex;
		flex: 1;
		padding: $spacer-x-small;
	}

	&__detail {
		align-self: flex-start;
		margin-top: $spacer;
		min-width: 500px;
		width: 500px;
		background-color: $darker-gray;
		border-radius: $border-radius;
		border: 1px solid $medium-gray;
		padding: $spacer-large;
		box-shadow: $box-shadow;

		h2, h3, h4 {
			margin: $spacer-2x-small 0;
		}

		h3 {
			font-size: 18px;
		}

		h4 {
			font-size: 14px;
		}

		small {
			color: $gray;
			font-size: 12px;
		}
	}

	&__content {
		max-height: calc(100vh - 105px);
	}

	&__list {
		flex: 1;
		margin-left: $spacer-large;
		height: 100%;
		overflow-y: scroll;
		overflow-x: hidden;
	}
}
