.m-project-navigator {
	&__part, &__item-loading, &__item, &__boards, &__add-cabinetgroup, &__select-cabinetgroup, &__select-cabinet {
		border-radius: $border-radius;
		padding: $spacer-2x-small;
		box-shadow: $box-shadow-light;
		margin-bottom: $spacer-x-small;
	}

	&__part {
		background-color: $input-background;
		display: flex;
		cursor: pointer;

		&:first-of-type {
			margin-top: 1px;
		}

		&:hover {
			outline: 1px solid $gray;
		}

		&__button {
			margin-right: $spacer-x-small;
		}

		&--active, &--active:hover {
			outline: 1px solid $primary;
		}

		&__info {
			flex: 1;

			h3 {
				margin: 0;
				padding: 0;
				font-size: 16px;
				color: $primary;
			}

			p {
				margin: $spacer-2x-small 0 0 0;
				padding: 0;
				font-size: 14px;
			}
		}

		&__meta {
			width: 50px;
			display: flex;
			align-items: flex-end;
		}

		&__actions {
			width: 80px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-end;

			p {
				margin: $spacer-x-small 0 0 0;
				padding: 0;
			}
		}
	}

	&__item-loading {
		background: $medium-gray;
		margin-left: $spacer-large;
		height: 55px;
		position: relative;
		overflow: hidden;

		animation-duration: 1.25s;
		animation-fill-mode: forwards;
		animation-iteration-count: infinite;
		animation-name: placeHolderShimmer;
		animation-timing-function: linear;
		background: linear-gradient(to right, $medium-gray 8%, rgba($gray, 0.6) 18%, $medium-gray 33%);
		background-size: 800px 104px;
	}

	&__item {
		background-color: $medium-gray;
		margin-left: $spacer-large;
		display: flex;
		cursor: pointer;

		&:hover {
			outline: 1px solid $gray;
		}

		&--active, &--active:hover {
			outline: 1px solid $primary;
		}

		&--part-active {
			outline: 1px solid #558466;
		}

		&__button {
			margin-right: $spacer-x-small;
		}

		&__info {
			flex: 1;

			h3 {
				margin: $spacer-2x-small 0 0 0;
				padding: 0;
				font-size: 16px;
				color: $white;
			}

			p {
				margin: 0;
				padding: 0;
				font-size: 14px;
				color: $light-gray;
			}
		}

		&__meta {
			width: 50px;
			display: flex;
			align-items: flex-end;
		}

		&__actions {
			width: 100px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-end;

			p {
				margin: $spacer-x-small 0 0 0;
				padding: 0;
			}
		}
	}

	&__add-cabinetgroup {
		background-color: $medium-gray;
	}

	&__select-cabinetgroup {
		background-color: $medium-gray;
		margin-left: $spacer-large;
		display: flex;
		align-items: center;
		cursor: pointer;

		&:hover {
			outline: 1px solid $gray;
		}

		&--disabled {
			opacity: 0.6;
			pointer-events: none;
		}

		p {
			margin: 0 0 0 $spacer-x-small;
		}
	}

	&__add-cabinet__wrapper {
		display: flex;
		flex-direction: row-reverse;
		align-items: flex-start;
		margin-bottom: $spacer-x-small;
		margin-left: $spacer-large;
	}

	&__add-item {
		display: flex;
		justify-content: flex-end;
		margin-left: $spacer-x-small;
	}

	&__select-cabinet__wrapper {
		flex: 1;
	}

	&__select-cabinet {
		background-color: $medium-gray;
		display: flex;
		align-items: center;
		cursor: pointer;

		&:hover {
			outline: 1px solid $gray;
		}

		p {
			margin: 0 0 0 $spacer-x-small;
		}
	}

	&__add-item {
		display: flex;
		justify-content: flex-end;
		margin-bottom: $spacer-x-small;
	}
}
