.o-navigation-panel {
	height: calc(100vh - 60px);
	display: flex;
	flex-direction: column;

	&__navigator {
		flex: 1;
		overflow-y: auto;

		> .a-card {
			height: 100%;
		}
	}

	.a-boards {
		margin-left: $spacer-2x-large !important;
	}
}
