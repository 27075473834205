.m-board-selection {
	min-height: 500px;

	&__info {
		width: 100%;
	}

	&__tabs {
		display: flex;
	}

	&__tab {
		background-color: $dark-gray;
		opacity: .6;
		padding: $spacer-x-small;
		border-top-left-radius: $border-radius;
		border-top-right-radius: $border-radius;
		cursor: pointer;

		&--active {
			opacity: 1;
		}
	}

	&__inner {
		display: flex;
		flex-direction: row;
		align-items: stretch;
	}

	&__detail {
		min-height: 272px;
		background-color: $dark-gray;
		padding: $spacer;
		border-radius: $border-radius;
		box-shadow: $box-shadow;

		h2, h3, h4 {
			margin: $spacer-2x-small 0;
		}

		h3 {
			font-size: 18px;
		}

		h4 {
			font-size: 14px;
		}

		small {
			color: $gray;
			font-size: 12px;
		}

		.o-material__image {
			flex: 240px;
			min-width: 240px;
			max-width: 240px;
			align-self: stretch;
			height: 240px;
			margin-right: $spacer;
			margin-bottom: 0;
		}
	}

	&__content {
		flex: 1;
	}

	&__table {
		max-height: 300px;
		overflow: auto;
		padding: 1px;

		thead {
			background-color: $darker-gray;
			margin-bottom: 1px;
		}
	}
}


@media screen and (max-width: 800px) {
	.m-board-selection__detail {
		flex-direction: column;
	}
}
