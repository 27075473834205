.m-handle-selection {
	&__variants {
		margin-left: 2px;
		margin-bottom: $spacer;
	}

	// &__favourites {
	// 	position: absolute;
	// 	top: 0;
	// 	right: 0;
	// }
}
