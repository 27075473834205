.m-account-switcher {
	display: flex;
	align-items: center;

	&__divider {
		display: inline-block;
		margin: 0 $spacer;
	}

	.a-input {
		width: 200px;
		margin-bottom: 0;

		.a-input__select.ng-select.ng-select-single {
			height: 25px;

			.ng-select-container {
				height: 25px;
				min-height: 25px;
				// background-color: transparent;
				box-shadow: none;

				.ng-value-container .ng-input > input {
					padding: $spacer-2x-small;
				}
			}
		}
	}
}
