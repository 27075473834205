@font-face {font-family: "kastenman-icon";
  src: url('kastenman-icon.eot?t=1671453243779'); /* IE9*/
  src: url('kastenman-icon.eot?t=1671453243779#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url("kastenman-icon.woff2?t=1671453243779") format("woff2"),
  url("kastenman-icon.woff?t=1671453243779") format("woff"),
  url('kastenman-icon.ttf?t=1671453243779') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  url('kastenman-icon.svg?t=1671453243779#kastenman-icon') format('svg'); /* iOS 4.1- */
}

[class^="kastenman-icon-"], [class*=" kastenman-icon-"] {
  font-family: 'kastenman-icon' !important;
  font-size: 16px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.kastenman-icon-admin:before { content: "\ea01"; }
.kastenman-icon-angle-down:before { content: "\ea02"; }
.kastenman-icon-angle-left:before { content: "\ea03"; }
.kastenman-icon-angle-right:before { content: "\ea04"; }
.kastenman-icon-angle-up:before { content: "\ea05"; }
.kastenman-icon-bars:before { content: "\ea06"; }
.kastenman-icon-cart:before { content: "\ea07"; }
.kastenman-icon-check:before { content: "\ea08"; }
.kastenman-icon-configurator:before { content: "\ea09"; }
.kastenman-icon-designer:before { content: "\ea0a"; }
.kastenman-icon-duplicate:before { content: "\ea0b"; }
.kastenman-icon-file-upload:before { content: "\ea0c"; }
.kastenman-icon-file:before { content: "\ea0d"; }
.kastenman-icon-filter:before { content: "\ea0e"; }
.kastenman-icon-fullscreen-off:before { content: "\ea0f"; }
.kastenman-icon-fullscreen-on:before { content: "\ea10"; }
.kastenman-icon-history:before { content: "\ea11"; }
.kastenman-icon-home:before { content: "\ea12"; }
.kastenman-icon-locked:before { content: "\ea13"; }
.kastenman-icon-materials:before { content: "\ea14"; }
.kastenman-icon-pencil:before { content: "\ea15"; }
.kastenman-icon-plus:before { content: "\ea16"; }
.kastenman-icon-rotate-left:before { content: "\ea17"; }
.kastenman-icon-save:before { content: "\ea18"; }
.kastenman-icon-search:before { content: "\ea19"; }
.kastenman-icon-speech-bubble:before { content: "\ea1a"; }
.kastenman-icon-spinner:before { content: "\ea1b"; }
.kastenman-icon-status:before { content: "\ea1c"; }
.kastenman-icon-trash:before { content: "\ea1d"; }
.kastenman-icon-truck:before { content: "\ea1e"; }
.kastenman-icon-user-add:before { content: "\ea1f"; }
.kastenman-icon-user-remove:before { content: "\ea20"; }
.kastenman-icon-user:before { content: "\ea21"; }
.kastenman-icon-wood:before { content: "\ea22"; }
.kastenman-icon-worker:before { content: "\ea23"; }
.kastenman-icon-x:before { content: "\ea24"; }

$kastenman-icon-admin: "\ea01";
$kastenman-icon-angle-down: "\ea02";
$kastenman-icon-angle-left: "\ea03";
$kastenman-icon-angle-right: "\ea04";
$kastenman-icon-angle-up: "\ea05";
$kastenman-icon-bars: "\ea06";
$kastenman-icon-cart: "\ea07";
$kastenman-icon-check: "\ea08";
$kastenman-icon-configurator: "\ea09";
$kastenman-icon-designer: "\ea0a";
$kastenman-icon-duplicate: "\ea0b";
$kastenman-icon-file-upload: "\ea0c";
$kastenman-icon-file: "\ea0d";
$kastenman-icon-filter: "\ea0e";
$kastenman-icon-fullscreen-off: "\ea0f";
$kastenman-icon-fullscreen-on: "\ea10";
$kastenman-icon-history: "\ea11";
$kastenman-icon-home: "\ea12";
$kastenman-icon-locked: "\ea13";
$kastenman-icon-materials: "\ea14";
$kastenman-icon-pencil: "\ea15";
$kastenman-icon-plus: "\ea16";
$kastenman-icon-rotate-left: "\ea17";
$kastenman-icon-save: "\ea18";
$kastenman-icon-search: "\ea19";
$kastenman-icon-speech-bubble: "\ea1a";
$kastenman-icon-spinner: "\ea1b";
$kastenman-icon-status: "\ea1c";
$kastenman-icon-trash: "\ea1d";
$kastenman-icon-truck: "\ea1e";
$kastenman-icon-user-add: "\ea1f";
$kastenman-icon-user-remove: "\ea20";
$kastenman-icon-user: "\ea21";
$kastenman-icon-wood: "\ea22";
$kastenman-icon-worker: "\ea23";
$kastenman-icon-x: "\ea24";

