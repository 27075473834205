.m-cutout {
	&__create-button {
		border: none;
		background-color: $search-gray;
		font-family: 'Calibri', sans-serif;
		padding: calc(#{$spacer-2x-small} + 2px) $spacer-2x-small;
		font-size: 1rem;
		box-shadow: $box-shadow;
		border-radius: $border-radius;
		margin-right: $spacer-x-small;
		cursor: pointer;
		margin-bottom: $spacer;

		img {
			display: block;
		}

		&:hover {
			color: $darker-gray;
		}

		.a-icon {
			font-size: 1.5rem;
		}

		&.m-customisation__selector--selected {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			padding-bottom: $spacer-x-large;
		}

		&.m-customisation__selector--button .a-icon {
			font-size: 0.7rem;
		}
	}

	&__type {
		width: 12px;

		img {
			width: 12px;
			height: auto;
		}
	}

	&__entry {
		form {
			display: flex;
			align-items: flex-start;
		}

		&:not(:last-of-type) {
			padding-bottom: $spacer-x-small;
			margin-bottom: $spacer-x-small;
			border-bottom: 1px solid $dark-gray;
		}
	}

	&__position {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		width: 140px;
	}

	&__meta {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		width: 90px;
	}

	&__actions {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		margin-left: auto;

		button:first-of-type {
			margin-right: $spacer-2x-small;
		}
	}

	&__diameter {
		width: 65px;
	}

	&__field {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		&:not(:last-of-type) {
			margin-bottom: $spacer-2x-small;
		}

		.a-input__radio {
			display: flex;
			align-items: center;
			margin: 3px 0;

			span {
				margin-right: $spacer-2x-small;
			}
		}

		span {
			font-size: 12px;
		}

		.a-button {
			background-color: $search-gray;
			color: $white;
			margin-left: $spacer-2x-small;
		}

		.a-input {
			margin-left: $spacer-2x-small;
			margin-bottom: 0;
		}
	}

	&__colour {
		display: flex;
		flex-direction: column;

		&__button {
			cursor: pointer;
			border-radius: $border-radius;
			background-color: transparent;
			border: 2px solid transparent;
			height: 20px;
			width: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0;

			&:not(:last-of-type) {
				margin-bottom: 6px;
			}

			&--active {
				border: 2px solid $primary;
			}

			&:hover {
				border: 2px solid $gray;
			}

			&--black {
				.m-cutout__colour__inner {
					background-color: #292929;
					height: 12px;
					width: 12px;
					border-radius: $border-radius;
				}
			}

			&--white {
				.m-cutout__colour__inner {
					background-color: #FFFFFF;
					height: 12px;
					width: 12px;
					border-radius: $border-radius;
				}
			}
		}
	}
}
