h1, h2, h3, h4, h5, h6, p {
	font-weight: 300;
	line-height: 1.5;
}

p {
	font-size: 14px;
}

.u-text-center {
	text-align: center;
}

.u-text-right {
	text-align: right;
}

.u-text-highlight {
	color: $primary;
}

.u-text-danger {
	color: $danger;
}

.u-text-warning {
	color: $warning;
}

.u-text-light {
	color: $gray
}

.u-text-white {
	color: $white
}

.u-text-muted {
	color: $light-gray
}

.u-text-number {
	font-family: 'Calibri', sans-serif;
	font-weight: 300;
}

.u-text--x-small {
	color: $light-gray;
	font-size: 10px !important;
	line-height: 0.6;
}

.u-text--small {
	color: $light-gray;
	font-size: 12px !important;
	line-height: 0.6;
}
