.u-wrapper {
	height: 100%;
	display: flex;
	justify-content: flex-start;
	flex: 1;

	main {
		flex: 1;
		display: flex;
		flex-direction: column;
		min-height: 0;
	}
}

.u-content {
	// min-height: calc(100vh - #{$header-height});
	overflow-y: auto;
	flex: 1;
	display: flex;

	> *:not(router-outlet) {
		display: flex;
		flex: 1;
	}
}
