.m-customisation {
	margin-top: $spacer-small;

	&--pending {
		.m-customisation__selector--selected, .m-customisation__response-actions p {
			background-color: $primary;
		}

		.a-tag {
			background-color: #D49043;
		}

		.m-customisation__input .NgxEditor {
			border-color: $primary;
		}
	}

	&__header {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;

		.a-tag {
			margin-bottom: $spacer-3x-small;
		}
	}

	&__selector {
		button {
			border: none;
			background-color: $light-gray;
			font-family: 'Calibri', sans-serif;
			padding: $spacer-x-small $spacer-x-small;
			font-size: 1rem;
			box-shadow: $box-shadow;
			border-radius: $border-radius;
			margin-right: $spacer-x-small;
			cursor: pointer;

			img {

			}

			&:hover {
				color: $darker-gray;
			}

			&.m-customisation__selector--selected {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				padding-bottom: $spacer-x-large;
			}

			&.m-customisation__selector--button .a-icon {
				font-size: 0.7rem;
			}
		}
	}

	&__input {
		&.m-customisation__input--border-fix .NgxEditor {
			border-top-left-radius: 0;
		}

		.NgxEditor {
			width: 100%;
			box-shadow: $box-shadow;
			border-radius: $border-radius;
			background-color: $light-gray;
			resize: vertical;
			border: 2px solid $gray;
			box-shadow: $box-shadow-inset;
			font-size: 14px;
			font-weight: 300;
			font-family: 'Roboto', "Helvetica Neue", sans-serif;

			p {
				margin-bottom: 0;
			}

			.ProseMirror {
				padding: $spacer-x-small;
			}
		}

		:focus {
			outline: none;
		}
	}

	&__file {
		display: flex;
		align-items: center;
		color: $light-gray;
		font-size: 0.9rem;
	}

	&__files > .a-button .a-icon {
		color: $light-gray;
	}

	&__response-actions {
		margin-top: $spacer-x-small;
		display: flex;
		align-items: center;

		p {
			color: $darker-gray;
			background-color: $gray;
			padding: $spacer-3x-small $spacer-x-small;
			border-radius: $border-radius;
			box-shadow: $box-shadow;
			font-family: 'Calibri', sans-serif;
			font-weight: normal;
		}
	}
}
