.m-ribbon-item {
	height: 100%;
	width: 180px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;
    overflow: hidden;

	form {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex: 1;

		.a-input__label {
			white-space: nowrap;
		}
	}

	&--x-small {
		width: 80px;
	}

	&--small {
		width: 100px;
	}

	&__actions {
		min-width: 50px;
		display: flex;
		justify-content: flex-end;

		.a-button {
			margin-top: $spacer;
			width: 20px;
			height: 20px;

			&.a-button--danger {
				background-color: $gray;
				color: $lighter-gray;

				&:hover {
					color: $lighter-gray;
					background-color: $danger;
				}
			}

			&.a-button--success {
				background-color: $gray;
				color: $primary;

				&:hover {
					color: $gray;
					background-color: $primary;
				}
			}

			.a-icon {
				font-size: 0.65rem;
			}
		}
	}

	&__button {
		box-shadow: $box-shadow-light;
		background-color: #505050;
		border: none;
		border-radius: $border-radius;
		height: 100%;
		width: calc(100% - 2px);
		cursor: pointer;
		padding: $spacer-x-small;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		margin: 1px;

		img {
			height: 100%;
			overflow: hidden;
		}

		&:hover {
			outline: 1px solid $primary;
		}
	}

	h4 {
		font-size: 10px;
		text-transform: uppercase;
		text-align: center;
		margin: 0 0 $spacer-2x-small 0;
		max-width: 104px;
		padding: 0;
	}

	&__content {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex: 1;
		overflow: hidden;
	}

	& .m-ribbon-item__form {
		margin-left: $spacer-x-small;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	&__form--compensate-padding {
		margin-bottom: -$spacer-2x-small;
	}
}
