.a-icon {
	display: block;

	&--spin {
		animation: spin 3s linear 0s infinite;
	}

	&--muted {
		color: $light-gray
	}
}

.kastenman-icon-configurator {
	transform: scale(1.25);
}
