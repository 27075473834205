.m-user-info {
	position: relative;

	&__button {
		background: none;
		padding: 0;
		border: none;
		font-family: "Roboto", "Helvetica Neue", sans-serif;
		color: $lighter-gray;
		cursor: pointer;
		display: flex;
		align-items: center;

		.a-icon {
			margin-left: $spacer-x-small;
			font-size: 1rem;
		}
	}

	.m-user-info__dropdown {
		position: absolute;
		top: calc(#{$header-height} - #{$spacer});
		right: $spacer-3x-small;
		background-color: $gray;
		box-shadow: $box-shadow;
		text-align: right;
		border-radius: $border-radius;
		padding: $spacer-small;
		width: 250px;
		z-index: 0;

		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			a {
				display: inline-block;
				padding: $spacer-x-small $spacer;
				text-transform: none;
				text-decoration: none;
				color: $white;
				font-size: 16px;
				width: 100%;
				border-radius: $border-radius;

				&:hover {
					outline: 1px solid $white;
				}
			}
		}
	}

	&__avatar {
		margin-right: 25px;

		img {
			width: 100px;
			height: 100px;
		}
	}

	&__name {
		font-weight: 300;
		font-size: 20px;
		line-height: 20px;
	}

	&__email {
		font-weight: 300;
		font-size: 13px;
		line-height: 11px;
	}
}

.signout-btn {
	margin-top: 40px;
}
