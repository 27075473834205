.m-handle-position {
	position: relative;
	height: 290px;
	width: 290px;
	background-color: $dark-gray;

	&__inner {
		height: 290px;
		width: 290px;
		top: 0;
		left: 0;
		background-color: $dark-gray;
		position: absolute;
	}

	&__button {
		position: absolute;
		background-color: $light-gray;
		border: none;
		width: 40px;
		height: 20px;
		cursor: pointer;
		margin: 0;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $white;

		&:disabled {
			opacity: 0.3;
		}

		&--selected {
			color: $darker-gray;
			background-color: $primary;
		}

		&--vlt, &--vlc, &--vlb, &--vrt, &--vrc, &--vrb {
			width: 20px;
			height: 40px;

			span {
				text-orientation: mixed;
				writing-mode: vertical-rl;
				line-height: 0;
			}
		}

		&--hlt {
			top: 5px;
			left: 35px;
		}

		&--hct {
			top: 5px;
			left: 50%;
			transform: translateX(-50%);
		}

		&--hrt {
			top: 5px;
			right: 35px;
		}

		&--vlt {
			left: 5px;
			top: 35px;
		}

		&--vlc {
			left: 5px;
			top: 50%;
			transform: translateY(-50%);
		}

		&--vlb {
			left: 5px;
			bottom: 35px;
		}

		&--hlb {
			bottom: 5px;
			left: 35px;
		}

		&--hcb {
			bottom: 5px;
			left: 50%;
			transform: translateX(-50%);
		}

		&--hrb {
			bottom: 5px;
			right: 35px;
		}

		&--vrt {
			right: 5px;
			top: 35px;
		}

		&--vrc {
			right: 5px;
			top: 50%;
			transform: translateY(-50%);
		}

		&--vrb {
			right: 5px;
			bottom: 35px;
		}

		&--vcc {
			left: 50%;
			top: 50%;
			transform: translateX(-50%) translateY(-100%);
			width: 20px;
			height: 40px;

			span {
				text-orientation: mixed;
				writing-mode: vertical-rl;
				line-height: 0;
			}
		}

		&--hcc {
			left: 50%;
			top: 50%;
			transform: translateX(-50%) translateY(10%);
		}
	}
}
