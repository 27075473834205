.m-status-history {
	position: relative;

	&::after {
		content: "";
		position: absolute;
		left: 0;
		top: -10px;
		height: calc(100% + 20px);
		width: 5px;
		background-color: $gray;
		border-radius: 5px;
	}

	&__item {
		position: relative;
		left: -3px;
		display: flex;
		align-items: center;
		z-index: 1;
		padding-left: $spacer-large;

		&::after {
			content: "";
			position: absolute;
			left: 0;
			top: 3px;
			width: 7px;
			height: 7px;
			border: 2px solid $primary;
			background-color: $dark-gray;
			border-radius: 10px;
		}

		&:not(:last-child) {
			margin-bottom: $spacer-large;
		}
	}

	.m-status-history__date {
		margin-left: $spacer;
		font-size: 0.8rem;
		color: $gray;
	}
}
