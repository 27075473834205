.m-filler-selector {
	display: grid;
	width: 100%;
	grid-template-columns: 1fr 1fr 1fr;
	column-gap: $spacer-x-small;
	row-gap: $spacer-x-small;
	padding-left: 1px;

	&__button {
		box-shadow: $box-shadow-light;
		background-color: #505050;
		border: none;
		border-radius: $border-radius;
		height: 96px;
		display: inline-block;
		margin-bottom: $spacer-x-small;
		cursor: pointer;
		position: relative;

		&--loading {
			img {
				opacity: 0.3;
			}

			.m-filler-selector__button__loading {
				display: flex;
			}
		}

		&__loading {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: none;
			align-items: center;
			justify-content: center;

			.a-icon {
				color: white;
				font-size: 3rem;
			}
		}

		&:hover {
			outline: 1px solid $primary;
		}

		&--active {
			outline: 1px solid $light-gray;
		}

		&:disabled {
			cursor: not-allowed;
			pointer-events: all;

			&:hover {
				outline: none;
			}
		}
	}

	&__description {
		text-align: center;
		margin: 0;
	}
}
