@use 'sass:math';

$border-radius: 3px;

// Spacing
$spacer: 1rem;

$spacer-2x-large: $spacer * 3;
$spacer-x-large: $spacer * 2;
$spacer-large: $spacer * 1.5;
$spacer-small: math.div($spacer, 1.5);
$spacer-x-small: math.div($spacer, 2);
$spacer-2x-small: math.div($spacer, 3);
$spacer-3x-small: math.div($spacer, 4);
$spacer-4x-small: math.div($spacer, 5);
$spacer-5x-small: math.div($spacer, 6);

$box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.16);
$box-shadow-inset: inset 3px 3px 4px 0px rgba(0,0,0,0.11);
$box-shadow-inset-large: inset 3px 3px 6px 0px rgba(0,0,0,0.16);
$box-shadow-light: 1px 1px 2px 0px rgba(0, 0, 0, 0.11);

$header-height: 46px;
