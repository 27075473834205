.m-outline-selector {
	display: grid;
	width: 100%;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: repeat(5, 136px);

	&__button {
		box-shadow: $box-shadow-light;
		background-color: #505050;
		border: none;
		border-radius: $border-radius;
		height: 128px;
		margin-right: $spacer-x-small;
		display: inline-block;
		margin-bottom: $spacer-x-small;
		cursor: pointer;

		&:hover {
			outline: 1px solid $primary;
		}
	}
}
