.a-outline {
	max-width: 112px;
	margin: 1px;
	height: calc(100% - 2px);

	img {
		position: relative;
	}

	&--OTCRL img {
		top: 10px;
	}

	&--OTCRR img {
		top: 10px;
	}

	&--OTCTL img {
		right: 10px;
		bottom: 10px;
	}

	&--OTCTM img {
		left: 10px;
		bottom: 10px;
	}

	&--OTCTR img {
		left: 10px;
		bottom: 10px;
	}

	&--OTSL img {
		right: 10px;
		bottom: 10px;
	}

	&--OTSR img {
		left: 10px;
		bottom: 10px;
	}

	&--OSCTB img {
		right: 10px;
		top: 10px;
	}

	&--OSCTBT img {
		right: 10px;
	}

	&--OSCTBST img {
		right: 10px;
	}

	&--OSCTT img {
		right: 10px;
		bottom: 10px;
	}

	&--OSST img {
		right: 10px;
		bottom: 10px;
	}

	&--OFBSL img {
		right: 10px;
		bottom: 10px;
	}

	&--OFBSR img {
		left: 10px;
		bottom: 10px;
	}
}
