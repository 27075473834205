.m-tabs {
	display: flex;
	align-items: center;

	.a-icon {
		font-size: 1rem;
		margin-right: $spacer;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			display: inline-block;
		}
	}

	.a-button {
		border-bottom: 4px solid transparent;
		border-radius: 0;
		position: relative;

		&:hover, &.a-button--active {
			border-bottom: 4px solid $primary;
		}

		&::after {
			content: "";
			position: absolute;
			right: 0;
			top: $spacer-x-small;
			width: 1px;
			height: 20px;
			background-color: $medium-gray;
		}

		&--small {
			&:hover, &.a-button--active {
				border-bottom: 3px solid $primary;
			}

			&::after {
				top: $spacer-x-small;
				height: 12px;
			}
		}
	}
}
