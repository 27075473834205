@keyframes spin {
    from {
        transform:rotate(0deg);
    }

    to {
        transform:rotate(360deg);
    }
}

@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}
