.m-cutting-list-table {
	width: 100%;
	border-collapse: collapse;
	position: relative;

	thead {
		position: sticky;
		top: 0;

		@media screen and (max-width: 1440px) {
			display: none;
		}

		tr th {
			font-weight: 300;
			font-size: 12px;
			text-align: center;
			padding: $spacer-2x-small $spacer-2x-small $spacer-x-small $spacer-2x-small;
			word-break: break-word;
			overflow-wrap: break-word;
			hyphens: auto;
		}
	}

	tbody {
		tr {
			outline: 1px solid transparent;
			border-radius: $border-radius;

			@media screen and (max-width: 1440px) {
				grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
				display: grid;
				background-color: $medium-gray;
				margin-bottom: $spacer-2x-small;
			}

			@media screen and (max-width: 1300px) {
				grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
				display: grid;
				background-color: $medium-gray;
				margin-bottom: $spacer-2x-small;
			}

			@media screen and (max-width: 1260px) {
				grid-template-columns: 1fr 1fr 1fr;
			}


			td {
				text-align: left;
				font-weight: 300;
				font-size: 14px;
				padding: $spacer-2x-small;

				p {
					font-weight: 300;
					font-size: 12px;
					text-align: center;
					min-height: 18px;

					@media screen and (min-width: 1440px) {
						display: none;
					}
				}
			}
		}
	}

	&__cell {
		padding: 0 $spacer-3x-small;

		.a-input {
			margin-bottom: 0;
			min-width: 60px;
		}
	}

	&__actions {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		height: 100%;
	}

	&__material {
		color: $white;
		background-color: $gray;
		cursor: pointer;
		border: none;
		width: 100%;
		font-family: 'Roboto', "Helvetica Neue", sans-serif;
		padding: $spacer-2x-small;
		text-align: left;
		font-weight: 300;
		border-radius: $border-radius;
		min-width: 100px;
	}
}
