.a-card {
	box-shadow: $box-shadow;
	border-radius: $border-radius;
	background-color: $dark-gray;
	padding: $spacer-large;
	word-break: break-word;
	position: relative;
	width: 100%;
	z-index: 2;

	&--light {
		background-color: $input-background;
	}

	&--small {
		padding: $spacer-small;
	}

	&--x-small {
		padding: $spacer-x-small;
	}

	&--scrollbar-fix {
		padding-right: 0;

		.a-card__content {
			padding-right: $spacer-small;
		}

		.ng-scrollbar {
			--scrollbar-padding: 3px
		}
	}

	&--active {
		border: 1px solid #558466;
	}

	&--container-row {
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: flex-start;
	}
	&--container-text {
		margin-bottom: 10px;
	}

	> h1 {
		font-size: 1.6rem;
		font-weight: 300;
		padding-bottom: $spacer;
		margin-bottom: $spacer;
		border-bottom: 1px solid rgba($gray, 0.10)
	}

	&__header {
		border-bottom: 1px solid rgba($gray, 0.10);
		padding: 0 0 $spacer-small 0;

		h2 {
			font-size: 1.6rem;
			margin: 0;
			padding: 0;
		}
	}

	> h3 {
		font-size: 16px;
		color: $primary;
		margin: 0;
		padding: 0;
	}

	> h4 {
		font-size: 12px;
		color: $lighter-gray;
		text-transform: uppercase;
		margin: 0 0 $spacer-small 0;
		padding: 0;
	}
}
