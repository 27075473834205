.m-configuration {
	&__header, &__item {
		width: 100%;
		margin-bottom: $spacer-2x-small;
		background-color: $input-background;
		display: flex;
		align-items: center;
		padding: $spacer-x-small;
		border-radius: $border-radius;
	}

	&--filler {
		img {
			height: 44px;
			width: 44px;
		}
	}

	&__header {
		img {
			height: 44px;
			width: 44px;
			margin-right: $spacer-small;
		}

		h4 {
			font-size: 12px;
			text-transform: uppercase;
			margin: 0;
			padding: 0;
		}
	}

	&__item {
		align-items: flex-start;

		.a-input__radio {
			font-size: 12px;
		}

		// .a-input:nth-last-child(2) {
		// 	margin-bottom: 0;
		// }

		p {
			font-size: 13px;
		}

		&-info {
			background-color: #505050;
			border-radius: $border-radius;
			box-shadow: $box-shadow;
			width: 100%;
			padding: $spacer;
			margin-top: $spacer-small;
			font-size: .75rem;

			span {
				margin-bottom: $spacer-small;
				display: inline-block;
			}

			img {
				width: 100%;
			}
		}

		&-list {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				display: flex;

				&::before {
					content: "\2022";
					color: $primary;
					min-width: 10px;
				}

				> span {
					min-width: 100px;
					font-size: 0.9rem;
				}
			}

			li:not(:last-of-type) {
				margin-bottom: $spacer-small;
			}
		}

		&-content {
			flex: 1;
			// overflow: hidden;
		}

		&-toggle {
			min-width: 25px;
		}

		&-actions {
			display: flex;
			justify-content: flex-end;

			.a-button {
				margin-top: $spacer;
				width: 20px;
				height: 20px;

				&.a-button--danger {
					background-color: $gray;
					color: $lighter-gray;

					&:hover {
						color: $lighter-gray;
						background-color: $danger;
					}
				}

				&.a-button--success {
					background-color: $gray;
					color: $primary;

					&:hover {
						color: $gray;
						background-color: $primary;
					}
				}

				.a-icon {
					font-size: 0.65rem;
				}
			}
		}
	}
}
